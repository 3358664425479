import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import networkLines from "./assests/networkLines.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg";
import reportImg from "./assests/report-img.png";
import "../styleguide.css";
import fileChartIcon from "./assests/fileChartIcon.svg";
import eyeline from "./assests/eyeline.svg";
import shieldGreenIcon from "./assests/shieldGreenIcon.svg";
import rupeeIcon from "./assests/rupeeIcon.svg";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import "./DarkEyeStyle.css";
import "../styleguide.css";
import darkeyeSnap from "./assests/darkeyeSnap.svg";
import dbIcon from "./assests/darkEyeIcons/dbIcon.svg";
import fileIcon from "./assests/darkEyeIcons/fileIcon.svg";
import nodeIcon from "./assests/darkEyeIcons/nodeIcon.svg";
import notifyIcon from "./assests/darkEyeIcons/notifyIcon.svg";
import searchIcon from "./assests/darkEyeIcons/searchIcon.svg";
import shieldFlashIcon from "./assests/darkEyeIcons/shieldFlashIcon.svg";
import Navbar from "../components/Navbar";
import Testimonies from "../components/Testimonies/Testimonies";
import reportImgSingle from "../pages/assests/report-img-2.png";
import Footer from "../components/Footer";
import DarkEyeReport from "./DarkEyeReport";
import { HeroComponent } from "../components/dark-eye/HeroComponent";
// import FooterDark from "../components/footer/FooterDark";
import { useNotification } from "../components/notification/NotificationContext";
import Button from "../components/ui/Button";

export default function DarkEye() {
  const faqData = [
    {
      question: "How often are alerts generated?",
      answer:
        "Alerts are generated based on specific conditions or triggers, which can vary depending on the system.",
    },
    {
      question: "What is the average response time?",
      answer:
        "The average response time is typically under 5 minutes, depending on the complexity of the issue.",
    },
    {
      question: "Can I customize the alerts?",
      answer:
        "Yes, you can customize alerts based on your preferences and system configurations.",
    },
    {
      question: "What platforms are supported?",
      answer:
        "Our service supports Windows, macOS, Linux, and mobile platforms like iOS and Android.",
    },
    {
      question: "Is there a trial period available?",
      answer: "Yes, we offer a 14-day free trial with access to all features.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const [switchToReport, setSwitchToReport] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [domain, setDomain] = useState("");
  const [alert, setAlert] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="website-layer">
      <div className="darkeye-website-v-layer">
        <div className="darkeye-main-wrapper">
          <Navbar activePage="darkeye" />

          {switchToReport ? (
            <DarkEyeReport reportData={reportData} domain={domain} setSwitchToReport={setSwitchToReport} />
          ) : (
            <div>
              <HeroComponent
                domain={domain}
                setDomain={setDomain}
                setReportData={setReportData}
                setSwitchToReport={setSwitchToReport}
                alert={alert}
                setAlert={setAlert}
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                          
                }}
              >
                <img
            src={darkeyeSnap}
            style={{ marginTop: "-2vw", position: "relative", width: "55vw" }}
          />
              </div>
              <div className="darkeye-services-section2">
                <div className="darkeye-div-2">
                  <button
                    className="darkeye-primary-btn-instance2"
                    divclassName="darkeye-design-component-instance-node"
                    property1="tag"
                  >
                    Apni Sec / Dark Eye Watcher
                  </button>
                  <div className="darkeye-section1-text">
                    Monitor and secure your organization against Emerging
                    threats from the dark web. With advanced intelligence to
                    Filter Valid Working Credentials and Leaks{" "}
                  </div>
                </div>
              </div>

              <div className="darkeye-report-section2">
                <div className="darkeye-section2-heading">
                  Unveiling the Hidden Risks on the Dark Web
                </div>
                <div className="darkeye-report-component131">
                  <div className="darkeye-section-1">
                    <div className="darkeye-content-1">
                      <div className="darkeye-text-wrapper-131">
                        Compromised Data
                      </div>

                      <p className="darkeye-comprehensive131">
                        Corporate Devices Compromised via Breaches, published On
                        the dark Web & Private forums
                      </p>
                    </div>
                  </div>

                  <div className="darkeye-section-2">
                    <div className="darkeye-content-1">
                      <div className="darkeye-text-wrapper-131">
                        Emerging Threats
                      </div>

                      <p className="darkeye-comprehensive131">
                        Identifying new threats targeted at your business
                        financial or Business impact.
                      </p>
                    </div>
                  </div>
                  <div className="darkeye-section-3">
                    <div className="darkeye-content-1">
                      <div className="darkeye-text-wrapper-131">
                        Brand Mentions
                      </div>

                      <p className="darkeye-comprehensive131">
                        Misuse of your brand name or trademarks in illicit
                        activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="darkeye-report-section3">
                <div className="darkeye-section3-heading">
                  Comprehensive Protection at Your Fingertips
                </div>
                <div className="darkeye-report-component131">
                  <div className="darkeye-section-1">
                    <div className="darkeye-content-1">
                      <img
                        className="darkeye-socialIcon132"
                        alt="Twitter"
                        src={searchIcon}
                      />
                      <div className="darkeye-text-wrapper-132">
                        Threat Intelligence
                      </div>
                      <p className="darkeye-comprehensive132">
                        Continuous surveillance on deep web with scraping
                        organization keywords.
                      </p>
                    </div>
                    <div className="darkeye-content-1">
                      <img
                        className="darkeye-socialIcon132"
                        alt="Twitter"
                        src={shieldFlashIcon}
                      />
                      <div className="darkeye-text-wrapper-132">
                        Incidents Mitigation
                      </div>
                      <p className="darkeye-comprehensive132">
                        Access tokens and cookies promptly revoked, and
                        Credentials are handled within defined SLAs.
                      </p>
                    </div>
                  </div>

                  <div className="darkeye-section-2">
                    <div className="darkeye-content-1">
                      <img
                        className="darkeye-socialIcon132"
                        alt="Twitter"
                        src={notifyIcon}
                      />
                      <div className="darkeye-text-wrapper-132">
                        AI-Powered Alerts
                      </div>
                      <p className="darkeye-comprehensive132">
                        Advanced algorithms detect and notify only working set
                        of credentials or cookies.
                      </p>
                    </div>
                    <div className="darkeye-content-1">
                      <img
                        className="darkeye-socialIcon132"
                        alt="Twitter"
                        src={fileChartIcon}
                      />
                      <div className="darkeye-text-wrapper-132">Reporting</div>
                      <p className="darkeye-comprehensive132">
                        Monthly Statistics on pro-active Validation and
                        Detection on compromised data
                      </p>
                    </div>
                  </div>
                  <div className="darkeye-section-3">
                    <div className="darkeye-content-1">
                      <img
                        className="darkeye-socialIcon132"
                        alt="Twitter"
                        src={nodeIcon}
                      />
                      <div className="darkeye-text-wrapper-132">
                        Leaks Classification
                      </div>
                      <p className="darkeye-comprehensive132">
                        Leaked credentials, Corporate Accounts, Sensitive
                        Documents, Cookies, PII data, Autofills and Browser
                        Profiles
                      </p>
                    </div>
                    <div className="darkeye-content-1">
                      <img
                        className="darkeye-socialIcon132"
                        alt="Twitter"
                        src={dbIcon}
                      />
                      <div className="darkeye-text-wrapper-132">
                        200+ sources
                      </div>
                      <p className="darkeye-comprehensive132">
                        Discord, IRC, Telegram, Darknet, Raid Forums etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="darkeye-stats-section-1">
                <div className="darkeye-div-3">
                  <div className="darkeye-frame-15">
                    <div className="darkeye-text-wrapper-141">280Bn+</div>

                    <div className="darkeye-text-wrapper-301">
                      Records Scraped
                    </div>
                  </div>

                  <div className="darkeye-frame-15">
                    <div className="darkeye-element">500K+</div>

                    <div className="darkeye-text-wrapper-301">
                      Compromised Credit Cards
                    </div>
                  </div>

                  <div className="darkeye-frame-15">
                    <div className="darkeye-text-wrapper-141">200TB+</div>

                    <div className="darkeye-text-wrapper-301">
                      Data Analysed
                    </div>
                  </div>

                  <div className="darkeye-frame-15">
                    <div className="darkeye-text-wrapper-141">950M+</div>

                    <div className="darkeye-text-wrapper-301">
                      Corporate Accounts
                    </div>
                  </div>
                </div>
              </div>

              <div className="darkeye-how-we-do-it-section">
                <div className="darkeye-title-2">
                  <div className="darkeye-overlap-group-6">
                    <img className="darkeye-line-5" alt="Line" src={line74} />

                    <div className="darkeye-how-we-do-it">How We Do It</div>
                  </div>
                </div>

                <div className="darkeye-w-rapper-wrapper">
                  <div className="darkeye-w-rapper">
                    <div className="darkeye-overlap-6">
                      <div className="darkeye-frame-16">
                        <div className="darkeye-content-2">
                          <div className="darkeye-img-wrapper">
                            <img
                              className="darkeye-watcher"
                              alt="Frame"
                              src={searchIcon}
                            />
                          </div>

                          <p className="darkeye-text-wrapper-32">
                            Define unique keywords, domain list, Third Party
                            Dashboards of organization
                          </p>
                        </div>

                        <div className="darkeye-content-3">
                          <div className="darkeye-img-wrapper">
                            <img
                              className="darkeye-watcher"
                              alt="Watcher"
                              src={dbIcon}
                            />
                          </div>

                          <p className="darkeye-text-wrapper-32">
                            Advanced AI filters identify and prioritize working
                            Credentials and Leaks directly impacting the
                            organization
                          </p>
                        </div>

                        <div className="darkeye-content-4">
                          <div className="darkeye-img-wrapper">
                            <img
                              className="darkeye-watcher"
                              alt="Report"
                              src={nodeIcon}
                            />
                          </div>

                          <p className="darkeye-text-wrapper-32">
                            Persistent Monitoring and 24/7 Support for Detailed
                            leak analysis
                          </p>
                        </div>
                      </div>

                      <div className="darkeye-frame-17">
                        <div className="darkeye-content-5">
                          <div className="darkeye-img-wrapper">
                            <img
                              className="darkeye-watcher"
                              alt="Penetration testing"
                              src={shieldFlashIcon}
                            />
                          </div>

                          <p className="darkeye-text-wrapper-32">
                            Continuous scanning of dark web forums,
                            marketplaces, and chat platforms.
                          </p>
                        </div>

                        <div className="darkeye-content-6">
                          <div className="darkeye-img-wrapper">
                            <img
                              className="darkeye-watcher"
                              alt="Blockchain"
                              src={notifyIcon}
                            />
                          </div>

                          <p className="darkeye-text-wrapper-32">
                            Instant alerts and Monthly reports sent to the team.
                          </p>
                        </div>
                      </div>

                      <div className="darkeye-group-13">
                        <div className="darkeye-overlap-6">
                          <div className="darkeye-lines">
                            <div className="darkeye-overlap-group-7">
                              <img
                                className="darkeye-line-6"
                                alt="Line"
                                src={line79}
                              />

                              <img
                                className="darkeye-line-7"
                                alt="Line"
                                src={line81}
                              />

                              <img
                                className="darkeye-line-8"
                                alt="Line"
                                src={line83}
                              />
                            </div>

                            <img
                              className="darkeye-line-9"
                              alt="Line"
                              src={line80}
                            />

                            <img
                              className="darkeye-line-10"
                              alt="Line"
                              src={line82}
                            />

                            <img
                              className="darkeye-line-11"
                              alt="Line"
                              src={line84}
                            />
                          </div>

                          <div className="darkeye-point">
                            <div className="darkeye-timelineDot" />

                            <div className="darkeye-timelineDot" />

                            <div className="darkeye-timelineDot" />

                            <div className="darkeye-timelineDot" />

                            <div className="darkeye-timelineDot" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="darkeye-report-section3">
                <div className="darkeye-section3-heading">
                  Subscriptions Plan
                </div>
                <div className="darkeye-report-component131">
                  <div className="darkeye-section-1">
                    <div className="darkeye-report-1">
                      <button
                        className="darkeye-primary-btn-instance2"
                        divclassName="darkeye-design-component-instance-node"
                        property1="tag"
                      >
                        Standard
                      </button>

                      <div className="darkeye-text-wrapper-1321">₹ 49,999</div>
                      <p className="darkeye-comprehensive1321">
                        Upto 10 domains or Keywords <br /> 24 x 7 Monitoring and
                        Support
                      </p>
                    </div>

                    <div className="darkeye-report-1">
                      <button
                        className="darkeye-primary-btn-instance2"
                        divclassName="darkeye-design-component-instance-node"
                        property1="tag"
                      >
                        Premium
                      </button>

                      <div className="darkeye-text-wrapper-1321">₹ 89,999</div>
                      <p className="darkeye-comprehensive1321">
                        Upto 10 domains or Keywords <br /> 24 x 7 Monitoring and
                        Support
                      </p>
                    </div>
                  </div>

                  <div className="darkeye-section-2">
                    <div className="darkeye-report-1">
                      <button
                        className="darkeye-primary-btn-instance2"
                        divclassName="darkeye-design-component-instance-node"
                        property1="tag"
                      >
                        Gold
                      </button>

                      <div className="darkeye-text-wrapper-1321">₹ 69,999</div>
                      <p className="darkeye-comprehensive1321">
                        Upto 10 domains or Keywords <br /> 24 x 7 Monitoring and
                        Support
                      </p>
                    </div>
                    <div className="darkeye-report-1">
                      <button
                        className="darkeye-primary-btn-instance2"
                        divclassName="darkeye-design-component-instance-node"
                        property1="tag"
                      >
                        Platinum
                      </button>

                      <div className="darkeye-text-wrapper-1321">₹ 99,999</div>
                      <p className="darkeye-comprehensive1321">
                        Upto 10 domains or Keywords <br /> 24 x 7 Monitoring and
                        Support
                      </p>
                    </div>
                  </div>
                </div>
                <div className="darkeye-section3-heading">
                  <p>
                    Above plans are monthly billed
                    <br />
                    The Domain indicates the root domain
                  </p>
                </div>
              </div>

              <div className="darkeye-testimonial-section">
                <div className="darkeye-testimonial-wrap">
                  <Testimonies activePage="darkeye" />
                </div>
              </div>

              <div className="darkeye-team-section">
                <div className="darkeye-text-wrapper-39">
                  Frequently Asked Questions
                </div>

                <div
                  style={{
                    padding: "20px",
                    fontFamily: "Arial, sans-serif",
                    width: "65vw",
                  }}
                >
                  {faqData.map((faq, index) => (
                    <div
                      key={index}
                      style={{
                        border: "1px solid #cacaca",
                        borderRadius: "5px",
                        marginBottom: "1vw",
                        backgroundColor: "#fafafa",
                        padding: "1vw",
                      }}
                    >
                      <div
                        onClick={() => toggleFAQ(index)}
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "1vw",
                          cursor: "pointer",
                          fontSize: "1.25vw",
                        }}
                      >
                        {faq.question}
                        <span
                          style={{
                            transform:
                              activeIndex === index
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        >
                          ▼
                        </span>
                      </div>
                      {activeIndex === index && (
                        <div
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            padding: "2vw",
                            fontSize: "1vw",
                            color: "#333",
                            borderTop: "1px solid #c9f9c9",
                          }}
                        >
                          {faq.answer}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="darkeye-CTA-section">
                {/* <div className="darkeye-background" /> */}
                <div className="darkeye-networkLinesWrapper">
                  {" "}
                  <img
                    className="darkeye-networkLines"
                    alt="Vector"
                    src={networkLines}
                  />
                </div>

                <div className="darkeye-div-2">
                  {/* <img
                className="report-img"
                alt="Report img"
                src={reportImgSingle}
              /> */}
                  <div className="darkeye-text-wrapper-40">
                    Protect Your Data Now
                  </div>
                </div>
                <div
                  className="darkeye-div-wrapper"
                  onClick={() =>
                    navigate("/contact-us", {
                      state: { activePage: "darkeye" }, // Passing 'darkeye' as the value for activePage
                    })
                  }
                >
                  <div className="darkeye-text-wrapper-6">Get Quote</div>
                </div>
                <div className="darkeye-networkLinesWrapper1">
                  {" "}
                  <img
                    className="darkeye-networkLines1"
                    alt="Vector"
                    src={networkLines}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
        {/* <FooterDark /> */}
      </div>
    </div>
  );
}
