import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg";
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png";
import tushar from "./assests/tushar.png";
import rajat from "./assests/rajat.jpg";
import shield from "./assests/shield.svg";
import networkLines from "./assests/networkLines.svg";
import brandLogo from "./assests/brand-logo-dk.svg";
import certified from "./assests/certified.svg";
import linkedin from "./assests/linkedin.svg";
import twitter from "./assests/twitter.svg";
import youtube from "./assests/youtube.svg";
import heroBrands from "./assests/heroBrands.svg";
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg";
import reportImg from "./assests/report-img.png";
import reportImgSingle from "./assests/report-img-single.png";
import "../styleguide.css";
import vector from "./assests/vector.svg";
import apple from "./assests/appreciationLogos/apple.svg";
import google from "./assests/appreciationLogos/google.svg";
import amazon from "./assests/appreciationLogos/amazon.svg";
import microsoft from "./assests/appreciationLogos/microsoft.svg";
import asus from "./assests/appreciationLogos/asus.svg";
import redbull from "./assests/appreciationLogos/redbull.svg";
import dell from "./assests/appreciationLogos/dell.svg";
import dominos from "./assests/appreciationLogos/dominos.svg";
import figma from "./assests/appreciationLogos/figma.svg";
import flipkart from "./assests/appreciationLogos/flipkart.svg";
import atlassian from "./assests/appreciationLogos/atlassian.svg";
import jira from "./assests/appreciationLogos/jira.svg";
import licious from "./assests/appreciationLogos/licious.svg";
import facebook from "./assests/appreciationLogos/facebook.svg";
import mastercard from "./assests/appreciationLogos/mastercard.svg";
import ncrb from "./assests/appreciationLogos/ncrb.svg";
import ncipc from "./assests/appreciationLogos/ncipc.svg";
import yahoo from "./assests/appreciationLogos/yahoo.svg";
import zomato from "./assests/appreciationLogos/zomato.svg";
import paytm from "./assests/appreciationLogos/paytm.svg";
import sony from "./assests/appreciationLogos/sony.svg";
import fileSearchIcon from "./assests/fileSearchIcon.svg";
import crosshairIcon from "./assests/crosshairIcon.svg";
import eyelineIcon from "./assests/eyelineIcon.svg";
import shieldIcon from "./assests/shieldIcon.svg";
import fileChartIcon from "./assests/fileChartIcon.svg";
import eyeline from "./assests/eyeline.svg";
import shieldGreenIcon from "./assests/shieldGreenIcon.svg";
import rupeeIcon from "./assests/rupeeIcon.svg";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import "./VaptStyle.css";
import "../styleguide.css";
import watcherImg from "./assests/WatcherDashboard.svg";
import darkeyeSnap from "./assests/darkeyeSnap.svg";
import dbIcon from "./assests/darkEyeIcons/dbIcon.svg";
import fileIcon from "./assests/darkEyeIcons/fileIcon.svg";
import nodeIcon from "./assests/darkEyeIcons/nodeIcon.svg";
import notifyIcon from "./assests/darkEyeIcons/notifyIcon.svg";
import searchIcon from "./assests/darkEyeIcons/searchIcon.svg";
import shieldFlashIcon from "./assests/darkEyeIcons/shieldFlashIcon.svg";
import Navbar from "../components/Navbar";
import Testimonies from "../components/Testimonies/Testimonies";
import Footer from "../components/Footer";

export default function vapt() {
  const faqData = [
    {
      question: "How often are alerts generated?",
      answer:
        "Alerts are generated based on specific conditions or triggers, which can vary depending on the system.",
    },
    {
      question: "What is the average response time?",
      answer:
        "The average response time is typically under 5 minutes, depending on the complexity of the issue.",
    },
    {
      question: "Can I customize the alerts?",
      answer:
        "Yes, you can customize alerts based on your preferences and system configurations.",
    },
    {
      question: "What platforms are supported?",
      answer:
        "Our service supports Windows, macOS, Linux, and mobile platforms like iOS and Android.",
    },
    {
      question: "Is there a trial period available?",
      answer: "Yes, we offer a 14-day free trial with access to all features.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us"); // Redirect to /contact-us
  };

  const handleLogoClick = () => {
    navigate("/"); // Redirect to /contact-us
  };
  return (
    <div className="website-layer">
      <div className="vapt-website-v-layer">
        <div className="vapt-main-wrapper">
          <Navbar activePage="vapt" />
          <div className="vapt-hero-section">
            <div className="vapt-hero-section-2">
              <div className="vapt-hero-header">
                <p className="vapt-defend-against-cyber">
                  <span className="vapt-span">
                    Vulnerability Assessment & Penetration Testing{" "}
                  </span>
                </p>

                <p className="vapt-p">
                  Comprehensive Security Assessments for Web, Mobile, API, and
                  Network with Retests and Certified Audit Reports.
                </p>
              </div>

              <div>
                <div
                  className="vapt-div-wrapper"
                  onClick={() =>
                    navigate("/contact-us", {
                      state: { activePage: "vapt" }, // Passing 'darkeye' as the value for activePage
                    })
                  }
                >
                  <div className="vapt-text-wrapper-6">Get Quote</div>
                </div>
              </div>

              <div className="vapt-trust">
                <div className="vapt-overall-rating">
                <div className="frame" style={{display: "flex", flexDirection: "column", gap: "0px"}}>
                    <img
                      className="img"
                      alt="overallRating"
                      src={overallRating}
                    />
                    <p style={{fontSize: "1vw", color: "white", margin: "0px", fontWeight: "600"}}>We secure 1000+ Organizations</p>
                  </div>
                </div>

                <div className="vapt-frame-2">
                  <div className="image-slider">
                    <img className="img-2" alt="Img" src={asus} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={amazon} />
                    <img className="img-2" alt="Img" src={redbull} />
                    <img className="img-2" alt="Img" src={dell} />
                    <img className="img-2" alt="Img" src={apple} />
                    <img className="img-2" alt="Img" src={dominos} />
                    <img className="img-2" alt="Img" src={flipkart} />
                    <img className="img-2" alt="Img" src={microsoft} />
                    <img className="img-2" alt="Img" src={atlassian} />
                    <img className="img-2" alt="Img" src={figma} />
                    <img className="img-2" alt="Img" src={jira} />
                    <img className="img-2" alt="Img" src={licious} />
                    <img className="img-2" alt="Img" src={facebook} />
                    <img className="img-2" alt="Img" src={mastercard} />
                    <img className="img-2" alt="Img" src={ncrb} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={ncipc} />
                    <img className="img-2" alt="Img" src={yahoo} />
                    <img className="img-2" alt="Img" src={zomato} />
                    <img className="img-2" alt="Img" src={paytm} />
                    <img className="img-2" alt="Img" src={sony} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            src={darkeyeSnap}
            style={{ marginTop: "-2vw", position: "relative", width: "55vw" }}
          />
          <div className="vapt-services-section2">
            <div className="vapt-div-2">
              <button
                className="vapt-primary-btn-instance2"
                divclassName="vapt-design-component-instance-node"
                property1="tag"
              >
                Apni Sec / VAPT
              </button>
              <div className="vapt-section1-text">
                Manual Blackbox & Whitebox Extensive security assessment of
                customer facing applications to identify vulnerabilities in
                applications & Systems.
              </div>
            </div>
          </div>

          <div className="vapt-report-section2">
            <div className="vapt-section2-heading">
              Patch the vulnerabilities before attackers exploit them
            </div>
            <div className="vapt-report-component131">
              <div className="vapt-section-1">
                <div className="vapt-content-1">
                  <div className="vapt-text-wrapper-131">
                    Bugs to Production
                  </div>

                  <p className="vapt-comprehensive131">
                    only TDD, BDD, QA testing before pushing the application to
                    production.
                  </p>
                </div>
              </div>

              <div className="vapt-section-2">
                <div className="vapt-content-1">
                  <div className="vapt-text-wrapper-131">Emerging Threats</div>

                  <p className="vapt-comprehensive131">
                    Business logic Flaws leading to unauthorized data leaks that
                    are not caught by linters or automated scanners.
                  </p>
                </div>
              </div>
              <div className="vapt-section-3">
                <div className="vapt-content-1">
                  <div className="vapt-text-wrapper-131">Feature releases</div>

                  <p className="vapt-comprehensive131">
                    Critical Flows such as payment checkout & Authentication
                    Fixes deployed without dynamic Security Testing.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="vapt-report-section3">
            <div className="vapt-section3-heading">
              Comprehensive Protection at Your Fingertips
            </div>
            <div className="vapt-report-component131">
              <div className="vapt-section-1">
                <div className="vapt-content-1">
                  <img
                    className="vapt-socialIcon132"
                    alt="Twitter"
                    src={searchIcon}
                  />
                  <div className="vapt-text-wrapper-132">Manual Assessment</div>
                  <p className="vapt-comprehensive132">
                    Apni Sec Team Certified Hackers expertises in Business Logic
                    Flaws that are not caught by automated scanners
                  </p>
                </div>
                <div className="vapt-content-1">
                  <img
                    className="vapt-socialIcon132"
                    alt="Twitter"
                    src={shieldFlashIcon}
                  />
                  <div className="vapt-text-wrapper-132">
                    Track Feature Releases
                  </div>
                  <p className="vapt-comprehensive132">
                    Alert and assess new endpoints or dynamic parameters
                    introduced in hot-fixes
                  </p>
                </div>
              </div>

              <div className="vapt-section-2">
                <div className="vapt-content-1">
                  <img
                    className="vapt-socialIcon132"
                    alt="Twitter"
                    src={notifyIcon}
                  />
                  <div className="vapt-text-wrapper-132">
                    End to End pentesting
                  </div>
                  <p className="vapt-comprehensive132">
                    In-depth VAPT of the entire application with multiple layers
                    focussing towards sensitive payment, authenticationand PII
                    data flows.
                  </p>
                </div>
                <div className="vapt-content-1">
                  <img
                    className="vapt-socialIcon132"
                    alt="Twitter"
                    src={fileChartIcon}
                  />
                  <div className="vapt-text-wrapper-132">
                    Reporting & Mitigation
                  </div>
                  <p className="vapt-comprehensive132">
                    Step by step mitigation steps for vulnerability discovered
                    and certified reports required for compliance.
                  </p>
                </div>
              </div>
              <div className="vapt-section-3">
                <div className="vapt-content-1">
                  <img
                    className="vapt-socialIcon132"
                    alt="Twitter"
                    src={nodeIcon}
                  />
                  <div className="vapt-text-wrapper-132">PR Reviews</div>
                  <p className="vapt-comprehensive132">
                    Review Pull Requests of Fixes applied by the developers to
                    figure out the patch can be bypassed.
                  </p>
                </div>
                <div className="vapt-content-1">
                  <img
                    className="vapt-socialIcon132"
                    alt="Twitter"
                    src={dbIcon}
                  />
                  <div className="vapt-text-wrapper-132">White Box Testing</div>
                  <p className="vapt-comprehensive132">
                    Manual Source code Review for internal routes being
                    vulnerable to input validation or injection flaws.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="vapt-stats-section-1">
            <div className="vapt-div-3">
              <div className="vapt-frame-15">
                <div className="vapt-text-wrapper-141">1400+</div>

                <div className="vapt-text-wrapper-301">
                  Applications Secured
                </div>
              </div>

              <div className="vapt-frame-15">
                <div className="vapt-element">150K+</div>
                <div className="vapt-text-wrapper-301">Endpoints Tested</div>
              </div>
              <div className="vapt-frame-15">
                <div className="vapt-text-wrapper-141">849M+</div>

                <div className="vapt-text-wrapper-301">
                  Lines of Code Reviewed
                </div>
              </div>

              <div className="vapt-frame-15">
                <div className="vapt-text-wrapper-141">99.99%</div>

                <div className="vapt-text-wrapper-301">Coverage</div>
              </div>
            </div>
          </div>

          <div className="vapt-how-we-do-it-section">
            <div className="vapt-title-2">
              <div className="vapt-overlap-group-6">
                <img className="vapt-line-5" alt="Line" src={line74} />

                <div className="vapt-how-we-do-it">How We Do It</div>
              </div>
            </div>

            <div className="vapt-w-rapper-wrapper">
              <div className="vapt-w-rapper">
                <div className="vapt-overlap-6">
                  <div className="vapt-frame-16">
                    <div className="vapt-content-2">
                      <div className="vapt-img-wrapper">
                        <img
                          className="vapt-watcher"
                          alt="Frame"
                          src={searchIcon}
                        />
                      </div>

                      <p className="vapt-text-wrapper-32">
                        Define unique keywords, domain list, Third Party
                        Dashboards of organization
                      </p>
                    </div>

                    <div className="vapt-content-3">
                      <div className="vapt-img-wrapper">
                        <img
                          className="vapt-watcher"
                          alt="Watcher"
                          src={dbIcon}
                        />
                      </div>

                      <p className="vapt-text-wrapper-32">
                        Advanced AI filters identify and prioritize working
                        Credentials and Leaks directly impacting the
                        organization
                      </p>
                    </div>

                    <div className="vapt-content-4">
                      <div className="vapt-img-wrapper">
                        <img
                          className="vapt-watcher"
                          alt="Report"
                          src={nodeIcon}
                        />
                      </div>

                      <p className="vapt-text-wrapper-32">
                        Persistent Monitoring and 24/7 Support for Detailed leak
                        analysis
                      </p>
                    </div>
                  </div>

                  <div className="vapt-frame-17">
                    <div className="vapt-content-5">
                      <div className="vapt-img-wrapper">
                        <img
                          className="vapt-watcher"
                          alt="Penetration testing"
                          src={shieldFlashIcon}
                        />
                      </div>

                      <p className="vapt-text-wrapper-32">
                        Continuous scanning of dark web forums, marketplaces,
                        and chat platforms.
                      </p>
                    </div>

                    <div className="vapt-content-6">
                      <div className="vapt-img-wrapper">
                        <img
                          className="vapt-watcher"
                          alt="Blockchain"
                          src={notifyIcon}
                        />
                      </div>

                      <p className="vapt-text-wrapper-32">
                        Instant alerts and Monthly reports sent to the team.
                      </p>
                    </div>
                  </div>

                  <div className="vapt-group-13">
                    <div className="vapt-overlap-6">
                      <div className="vapt-lines">
                        <div className="vapt-overlap-group-7">
                          <img
                            className="vapt-line-6"
                            alt="Line"
                            src={line79}
                          />

                          <img
                            className="vapt-line-7"
                            alt="Line"
                            src={line81}
                          />

                          <img
                            className="vapt-line-8"
                            alt="Line"
                            src={line83}
                          />
                        </div>

                        <img className="vapt-line-9" alt="Line" src={line80} />

                        <img className="vapt-line-10" alt="Line" src={line82} />

                        <img className="vapt-line-11" alt="Line" src={line84} />
                      </div>

                      <div className="vapt-point">
                        <div className="vapt-timelineDot" />

                        <div className="vapt-timelineDot" />

                        <div className="vapt-timelineDot" />

                        <div className="vapt-timelineDot" />

                        <div className="vapt-timelineDot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vapt-about-section">
            <div className="vapt-about-component">
              <div className="vapt-col">
                <img
                  className="vapt-report-img"
                  alt="Report img"
                  src={reportImgSingle}
                />
              </div>

              <div className="vapt-col-2">
                <div className="vapt-div-4">
                  <div className="vapt-text-wrapper-36">Sample VAPT Report</div>
                  <div className="vapt-text-wrapper-37">
                    Comprehensive evaluations to identify Server and Client
                    vulnerabilities with easy to understand Steps of
                    reproduction and Proof of Concept.
                  </div>
                  <div className="vapt-div-wrapper">
                    <div
                      className="cloudsecurity-text-wrapper-6"
                      onClick={() =>
                        window.open(
                          "https://assets.apnisec.com/public/Sample+Reports.zip",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Download Sample Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="vapt-testimonial-section">
            <div className="vapt-testimonial-wrap">
              <Testimonies activePage="vapt" />
            </div>
          </div>

          <div className="vapt-team-section">
            <div className="vapt-text-wrapper-39">
              Frequently Asked Questions
            </div>

            <div
              style={{
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                width: "65vw",
              }}
            >
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #54f181",
                    borderRadius: "5px",
                    marginBottom: "1vw",
                    backgroundColor: "rgba(84,241,149,0.1)",
                    padding: "1vw",
                  }}
                >
                  <div
                    onClick={() => toggleFAQ(index)}
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1vw",
                      cursor: "pointer",
                      fontSize: "1.25vw",
                    }}
                  >
                    {faq.question}
                    <span
                      style={{
                        transform:
                          activeIndex === index
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      ▼
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        padding: "2vw",
                        fontSize: "1vw",
                        color: "#333",
                        borderTop: "1px solid #c9f9c9",
                      }}
                    >
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="vapt-CTA-section">
            {/* <div className="vapt-background" /> */}
            <div className="vapt-networkLinesWrapper">
              {" "}
              <img
                className="vapt-networkLines"
                alt="Vector"
                src={networkLines}
              />
            </div>

            <div className="vapt-div-2">
              <div className="vapt-text-wrapper-40">Protect Your Data Now</div>
            </div>
            <div
              className="vapt-div-wrapper"
              onClick={() =>
                navigate("/contact-us", {
                  state: { activePage: "vapt" }, // Passing 'darkeye' as the value for activePage
                })
              }
            >
              <div className="vapt-text-wrapper-6">Get Quote</div>
            </div>
            <div className="vapt-networkLinesWrapper1">
              {" "}
              <img
                className="vapt-networkLines1"
                alt="Vector"
                src={networkLines}
              />
            </div>
          </div>
          <Footer />
       
        </div>
      </div>
    </div>
  );
}
