import { useState } from "react";
import reportImgSingle from "../../pages/assests/report-img-2.png";
import overallRating from "../../pages/assests/overallRating.svg";
import vector from "../../pages/assests/vector.svg";
import apple from "../../pages/assests/appreciationLogos/apple.svg";
import google from "../../pages/assests/appreciationLogos/google.svg";
import amazon from "../../pages/assests/appreciationLogos/amazon.svg";
import microsoft from "../../pages/assests/appreciationLogos/microsoft.svg";
import asus from "../../pages/assests/appreciationLogos/asus.svg";
import redbull from "../../pages/assests/appreciationLogos/redbull.svg";
import dell from "../../pages/assests/appreciationLogos/dell.svg";
import dominos from "../../pages/assests/appreciationLogos/dominos.svg";
import figma from "../../pages/assests/appreciationLogos/figma.svg";
import flipkart from "../../pages/assests/appreciationLogos/flipkart.svg";
import atlassian from "../../pages/assests/appreciationLogos/atlassian.svg";
import jira from "../../pages/assests/appreciationLogos/jira.svg";
import licious from "../../pages/assests/appreciationLogos/licious.svg";
import facebook from "../../pages/assests/appreciationLogos/facebook.svg";
import mastercard from "../../pages/assests/appreciationLogos/mastercard.svg";
import ncrb from "../../pages/assests/appreciationLogos/ncrb.svg";
import ncipc from "../../pages/assests/appreciationLogos/ncipc.svg";
import yahoo from "../../pages/assests/appreciationLogos/yahoo.svg";
import zomato from "../../pages/assests/appreciationLogos/zomato.svg";
import paytm from "../../pages/assests/appreciationLogos/paytm.svg";
import sony from "../../pages/assests/appreciationLogos/sony.svg";
import { useNotification } from "../notification/NotificationContext";
import { useNavigate } from "react-router-dom";
import { Success, Warning } from "../notification/Alert";

export const HeroComponent = ({
  setReportData,
  setSwitchToReport,
  domain,
  setDomain,
  alert,
  setAlert,
}) => {
  
  const navigate = useNavigate();
  

  async function handleSubmit(e) {
    e.preventDefault();

    setSwitchToReport(true);

    const payload = {
      domain: domain,
    };

    if(domain === "") {
      setAlert(<Warning text="Please enter a domain" />);
      setSwitchToReport(false);

      setTimeout(() => {
        setAlert(null);
      }, 20000);
      return; 
    }

    try {
      const response = await fetch("https://api.apnisec.com/darkeye-stats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        const errorMessage = errorData.message || errorData.error;
        setAlert(<Warning text={errorMessage} />);

        setSwitchToReport(false);
      } else if (response.ok) {
        const data = await response.json();

        setReportData(data);
      } else {
        console.log(response.statusText);
        
        setAlert(<Warning text={response.statusText} />);

        setSwitchToReport(false);
      }
    } catch (error) {
      setAlert(<Warning text="Something went wrong. Please try again later" />);

      setSwitchToReport(false);
    }

    setTimeout(() => {
      setAlert(null);
    }, 20000);
  }

  return (
    <div className="darkeye-hero-section">
      <div className="darkeye-hero-section-2">
        <div style={{ display: "flex", gap: "10vw" }}>
          <div style={{ width: "40vw", marginTop: "2vw" }}>
            <div className="darkeye-hero-header">
              <p className="darkeye-defend-against-cyber">
                <span className="darkeye-span">Dark Eye Watcher </span>
              </p>

              <p className="darkeye-p">
                Get real-time alerts on dark web leaks to protect your brand's
                reputation
              </p>
            </div>

            <div>
              <div style={{ marginTop: "2vw" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "#222222",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  <input
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="Type Your Domain"
                    style={{
                      padding: "10px 15px",
                      fontSize: "16px",
                      color: "#e0e0e0",
                      backgroundColor: "transparent",
                      border: "none",
                      borderRadius: "5px",
                      outline: "none",
                      width: "300px",
                      margin: "0px",
                    }}
                  />
                  <button
                    style={{
                      padding: "1vw 2vw",
                      fontSize: "1vw",
                      color: "#000",
                      backgroundColor: "#B4B4B4",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      textAlign: "center",
                      margin: "0px",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#fff")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#B4B4B4")
                    }
                    onClick={handleSubmit}
                  >
                    Get your free Dark Web Report
                  </button>
                </div>
              </div>

              <div>
                <div style={{ marginTop: "20px" }}>
                  <button
                    style={{
                      padding: "1vw 2vw",
                      fontSize: "1vw",
                      color: "#000",
                      backgroundColor: "#B4B4B4",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      textAlign: "center",
                      margin: "0px",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#fff")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#B4B4B4")
                    }
                    onClick={() =>
                      navigate("/contact-us", {
                        state: { activePage: "darkeye" }, // Passing 'darkeye' as the value for activePage
                      })
                    }
                  >
                    Get Quote
                  </button>
                </div>

                <div style={{ marginTop: "20px" }} />
                {alert}
              </div>
            </div>
          </div>

          <img
             className="report-img-single-2"
            alt="Report img"
            src={reportImgSingle}
            style={{
              // boxShadow: "-1px -1px 15px rgba(139, 240, 241, 0.5)",
              width: "20vw",
            }}
          />
        </div>

        {/* Rating and Brands */}
        <div className="darkeye-trust">
          {/* Rating */}
          <div className="darkeye-overall-rating">
            <div
              className="frame"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}
            >
              <img className="img" alt="overallRating" src={overallRating} />
              <p
                style={{
                  fontSize: "1vw",
                  color: "white",
                  margin: "0px",
                  fontWeight: "600",
                }}
              >
                We secure 1000+ Organizations
              </p>
            </div>
          </div>

          {/* brands */}
          <div className="darkeye-frame-2">
            <div className="image-slider">
              <img className="img-2" alt="Img" src={asus} />
              <img className="img-2" alt="Img" src={google} />
              <img className="img-2" alt="Img" src={amazon} />
              <img className="img-2" alt="Img" src={redbull} />
              <img className="img-2" alt="Img" src={dell} />
              <img className="img-2" alt="Img" src={apple} />
              <img className="img-2" alt="Img" src={dominos} />
              <img className="img-2" alt="Img" src={flipkart} />
              <img className="img-2" alt="Img" src={microsoft} />
              <img className="img-2" alt="Img" src={atlassian} />
              <img className="img-2" alt="Img" src={figma} />
              <img className="img-2" alt="Img" src={jira} />
              <img className="img-2" alt="Img" src={licious} />
              <img className="img-2" alt="Img" src={facebook} />
              <img className="img-2" alt="Img" src={mastercard} />
              <img className="img-2" alt="Img" src={ncrb} />
              <img className="img-2" alt="Img" src={google} />
              <img className="img-2" alt="Img" src={ncipc} />
              <img className="img-2" alt="Img" src={yahoo} />
              <img className="img-2" alt="Img" src={zomato} />
              <img className="img-2" alt="Img" src={paytm} />
              <img className="img-2" alt="Img" src={sony} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
