export function Warning({ text }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        gap: "10px",
        borderRadius: "4px",
        border: "1px solid rgba(255, 51, 51, 0.20)",
        background: "rgba(255, 51, 51, 0.10)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
        color: "#FF3333",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
      >
        <path
          d="M23.1948 42.0488C12.8395 42.0488 4.44482 33.6541 4.44482 23.2988C4.44482 12.9435 12.8395 4.54883 23.1948 4.54883C33.5501 4.54883 41.9448 12.9435 41.9448 23.2988C41.9448 33.6541 33.5501 42.0488 23.1948 42.0488ZM23.1948 38.2988C31.4791 38.2988 38.1948 31.5831 38.1948 23.2988C38.1948 15.0146 31.4791 8.29883 23.1948 8.29883C14.9105 8.29883 8.19482 15.0146 8.19482 23.2988C8.19482 31.5831 14.9105 38.2988 23.1948 38.2988ZM21.3198 28.9238H25.0698V32.6738H21.3198V28.9238ZM21.3198 13.9238H25.0698V25.1738H21.3198V13.9238Z"
          fill="#FF3333"
        />
      </svg>

      {text}
    </div>
  );
}

export function Success({ text , color="black"}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        gap: "10px",
        borderRadius: "4px",
        border: "1px solid rgba(71, 254, 44, 0.20)",
        background: "rgba(71, 254, 44, 0.10)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
        color,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M6 18.7988C6 12.1714 11.3726 6.79883 18 6.79883C24.6275 6.79883 30 12.1714 30 18.7988C30 25.4263 24.6275 30.7988 18 30.7988C11.3726 30.7988 6 25.4263 6 18.7988ZM18 3.79883C9.71572 3.79883 3 10.5146 3 18.7988C3 27.083 9.71572 33.7988 18 33.7988C26.2842 33.7988 33 27.083 33 18.7988C33 10.5146 26.2842 3.79883 18 3.79883ZM26.1856 14.9845L24.0644 12.8632L16.5 20.4275L12.3107 16.2382L10.1893 18.3595L16.5 24.6701L26.1856 14.9845Z"
          fill="#47FE2C"
        />
      </svg>

      {text}
    </div>
  );
}

export function Info({ text }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        gap: "10px",
        borderRadius: "4px",
        border: "1px solid rgba(59, 130, 246, 0.20)",
        background: "rgba(59, 130, 246, 0.10)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
        color: "#3B82F6",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M18 3.79883C9.71572 3.79883 3 10.5146 3 18.7988C3 27.083 9.71572 33.7988 18 33.7988C26.2842 33.7988 33 27.083 33 18.7988C33 10.5146 26.2842 3.79883 18 3.79883ZM18 30.7988C11.3726 30.7988 6 25.4263 6 18.7988C6 12.1714 11.3726 6.79883 18 6.79883C24.6275 6.79883 30 12.1714 30 18.7988C30 25.4263 24.6275 30.7988 18 30.7988ZM18 10.7988C17.4477 10.7988 17 11.2465 17 11.7988V17.7988C17 18.3511 17.4477 18.7988 18 18.7988C18.5523 18.7988 19 18.3511 19 17.7988V11.7988C19 11.2465 18.5523 10.7988 18 10.7988ZM18 21.7988C17.4477 21.7988 17 22.2465 17 22.7988V24.7988C17 25.3511 17.4477 25.7988 18 25.7988C18.5523 25.7988 19 25.3511 19 24.7988V22.7988C19 22.2465 18.5523 21.7988 18 21.7988Z"
          fill="#3B82F6"
        />
      </svg>

      {text}
    </div>
  );
}

export function DarkAlert({ text , icon="rotate"}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "16px 30px",
        gap: "10px",
        borderRadius: "4px",
        border: "1px solid rgba(245, 255, 252, 0.20)",
        background: "rgba(245, 255, 252, 0.05)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
        color: "#F5FFFC",
      }}
    >
     {iconMap[icon]}

      {text}
    </div>
  );
}


const iconMap = {
  warning: ( <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
  >
    <path
      d="M23.1948 42.0488C12.8395 42.0488 4.44482 33.6541 4.44482 23.2988C4.44482 12.9435 12.8395 4.54883 23.1948 4.54883C33.5501 4.54883 41.9448 12.9435 41.9448 23.2988C41.9448 33.6541 33.5501 42.0488 23.1948 42.0488ZM23.1948 38.2988C31.4791 38.2988 38.1948 31.5831 38.1948 23.2988C38.1948 15.0146 31.4791 8.29883 23.1948 8.29883C14.9105 8.29883 8.19482 15.0146 8.19482 23.2988C8.19482 31.5831 14.9105 38.2988 23.1948 38.2988ZM21.3198 28.9238H25.0698V32.6738H21.3198V28.9238ZM21.3198 13.9238H25.0698V25.1738H21.3198V13.9238Z"
      fill="#FF3333"
    />
  </svg>),
  success: (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
  >
    <path
      d="M6 18.7988C6 12.1714 11.3726 6.79883 18 6.79883C24.6275 6.79883 30 12.1714 30 18.7988C30 25.4263 24.6275 30.7988 18 30.7988C11.3726 30.7988 6 25.4263 6 18.7988ZM18 3.79883C9.71572 3.79883 3 10.5146 3 18.7988C3 27.083 9.71572 33.7988 18 33.7988C26.2842 33.7988 33 27.083 33 18.7988C33 10.5146 26.2842 3.79883 18 3.79883ZM26.1856 14.9845L24.0644 12.8632L16.5 20.4275L12.3107 16.2382L10.1893 18.3595L16.5 24.6701L26.1856 14.9845Z"
      fill="#47FE2C"
    />
  </svg>),
  info: (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
  >
    <path
      d="M18 3.79883C9.71572 3.79883 3 10.5146 3 18.7988C3 27.083 9.71572 33.7988 18 33.7988C26.2842 33.7988 33 27.083 33 18.7988C33 10.5146 26.2842 3.79883 18 3.79883ZM18 30.7988C11.3726 30.7988 6 25.4263 6 18.7988C6 12.1714 11.3726 6.79883 18 6.79883C24.6275 6.79883 30 12.1714 30 18.7988C30 25.4263 24.6275 30.7988 18 30.7988ZM18 10.7988C17.4477 10.7988 17 11.2465 17 11.7988V17.7988C17 18.3511 17.4477 18.7988 18 18.7988C18.5523 18.7988 19 18.3511 19 17.7988V11.7988C19 11.2465 18.5523 10.7988 18 10.7988ZM18 21.7988C17.4477 21.7988 17 22.2465 17 22.7988V24.7988C17 25.3511 17.4477 25.7988 18 25.7988C18.5523 25.7988 19 25.3511 19 24.7988V22.7988C19 22.2465 18.5523 21.7988 18 21.7988Z"
      fill="#3B82F6"
    />
  </svg>),
  rotate: ( <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M12 4.29883C9.25144 4.29883 6.82508 5.68509 5.38443 7.79883H8V9.79883H2V3.79883H4V6.29819C5.82381 3.87049 8.72764 2.29883 12 2.29883C17.5228 2.29883 22 6.77598 22 12.2988H20C20 7.88055 16.4183 4.29883 12 4.29883ZM4 12.2988C4 16.7171 7.58172 20.2988 12 20.2988C14.7486 20.2988 17.1749 18.9125 18.6156 16.7988H16V14.7988H22V20.7988H20V18.2994C18.1762 20.7271 15.2724 22.2988 12 22.2988C6.47715 22.2988 2 17.8216 2 12.2988H4Z"
      fill="#F5FFFC"
    />
  </svg>)

}