import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg";
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png";
import tushar from "./assests/tushar.png";
import rajat from "./assests/rajat.jpg";
import shield from "./assests/shield.svg";
import networkLines from "./assests/networkLines.svg";
import brandLogo from "./assests/brand-logo-dk.svg";
import certified from "./assests/certified.svg";
import linkedin from "./assests/linkedin.svg";
import twitter from "./assests/twitter.svg";
import youtube from "./assests/youtube.svg";
import heroBrands from "./assests/heroBrands.svg";
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg";
import reportImg from "./assests/report-img.png";
import reportImgSingle from "./assests/report-img-single.png";
import "../styleguide.css";
import vector from "./assests/vector.svg";
import apple from "./assests/appreciationLogos/apple.svg";
import google from "./assests/appreciationLogos/google.svg";
import amazon from "./assests/appreciationLogos/amazon.svg";
import microsoft from "./assests/appreciationLogos/microsoft.svg";
import asus from "./assests/appreciationLogos/asus.svg";
import redbull from "./assests/appreciationLogos/redbull.svg";
import dell from "./assests/appreciationLogos/dell.svg";
import dominos from "./assests/appreciationLogos/dominos.svg";
import figma from "./assests/appreciationLogos/figma.svg";
import flipkart from "./assests/appreciationLogos/flipkart.svg";
import atlassian from "./assests/appreciationLogos/atlassian.svg";
import jira from "./assests/appreciationLogos/jira.svg";
import licious from "./assests/appreciationLogos/licious.svg";
import facebook from "./assests/appreciationLogos/facebook.svg";
import mastercard from "./assests/appreciationLogos/mastercard.svg";
import ncrb from "./assests/appreciationLogos/ncrb.svg";
import ncipc from "./assests/appreciationLogos/ncipc.svg";
import yahoo from "./assests/appreciationLogos/yahoo.svg";
import zomato from "./assests/appreciationLogos/zomato.svg";
import paytm from "./assests/appreciationLogos/paytm.svg";
import sony from "./assests/appreciationLogos/sony.svg";
import fileSearchIcon from "./assests/fileSearchIcon.svg";
import crosshairIcon from "./assests/crosshairIcon.svg";
import eyelineIcon from "./assests/eyelineIcon.svg";
import shieldIcon from "./assests/shieldIcon.svg";
import fileChartIcon from "./assests/fileChartIcon.svg";
import eyeline from "./assests/eyeline.svg";
import shieldGreenIcon from "./assests/shieldGreenIcon.svg";
import rupeeIcon from "./assests/rupeeIcon.svg";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import "./CloudSecurityStyle.css";
import "../styleguide.css";
import watcherImg from "./assests/WatcherDashboard.svg";
import darkeyeSnap from "./assests/darkeyeSnap.svg";
import dbIcon from "./assests/darkEyeIcons/dbIcon.svg";
import fileIcon from "./assests/darkEyeIcons/fileIcon.svg";
import nodeIcon from "./assests/darkEyeIcons/nodeIcon.svg";
import notifyIcon from "./assests/darkEyeIcons/notifyIcon.svg";
import searchIcon from "./assests/darkEyeIcons/searchIcon.svg";
import shieldFlashIcon from "./assests/darkEyeIcons/shieldFlashIcon.svg";
import Navbar from "../components/Navbar";
import Testimonies from "../components/Testimonies/Testimonies";
import Footer from "../components/Footer";

export default function CloudSecurity() {
  const faqData = [
    {
      question: "How often are alerts generated?",
      answer:
        "Alerts are generated based on specific conditions or triggers, which can vary depending on the system.",
    },
    {
      question: "What is the average response time?",
      answer:
        "The average response time is typically under 5 minutes, depending on the complexity of the issue.",
    },
    {
      question: "Can I customize the alerts?",
      answer:
        "Yes, you can customize alerts based on your preferences and system configurations.",
    },
    {
      question: "What platforms are supported?",
      answer:
        "Our service supports Windows, macOS, Linux, and mobile platforms like iOS and Android.",
    },
    {
      question: "Is there a trial period available?",
      answer: "Yes, we offer a 14-day free trial with access to all features.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us"); // Redirect to /contact-us
  };

  const handleLogoClick = () => {
    navigate("/"); // Redirect to /contact-us
  };
  return (
    <div className="website-layer">
      <div className="cloudsecurity-website-v-layer">
        <div className="cloudsecurity-main-wrapper">
          <Navbar activePage="cloudsecurity" />
          <div className="cloudsecurity-hero-section">
            <div className="cloudsecurity-hero-section-2">
              <div className="cloudsecurity-hero-header">
                <p className="cloudsecurity-defend-against-cyber">
                  <span className="cloudsecurity-span">Cloud Security</span>
                </p>

                <p className="cloudsecurity-p">
                  AWS AZure GCP Private Cloud Monitoring
                </p>
              </div>

              <div>
                <div
                  className="cloudsecurity-div-wrapper"
                  onClick={() =>
                    navigate("/contact-us", {
                      state: { activePage: "cloudsecurity" }, // Passing 'darkeye' as the value for activePage
                    })
                  }
                >
                  <div className="cloudsecurity-text-wrapper-6">Get Quote</div>
                </div>
              </div>

              <div className="cloudsecurity-trust">
                <div className="cloudsecurity-overall-rating">
                <div className="frame" style={{display: "flex", flexDirection: "column", gap: "0px"}}>
                    <img
                      className="img"
                      alt="overallRating"
                      src={overallRating}
                    />
                    <p style={{fontSize: "1vw", color: "white", margin: "0px", fontWeight: "600"}}>We secure 1000+ Organizations</p>
                  </div>
                </div>

                <div className="cloudsecurity-frame-2">
                  <div className="image-slider">
                    <img className="img-2" alt="Img" src={asus} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={amazon} />
                    <img className="img-2" alt="Img" src={redbull} />
                    <img className="img-2" alt="Img" src={dell} />
                    <img className="img-2" alt="Img" src={apple} />
                    <img className="img-2" alt="Img" src={dominos} />
                    <img className="img-2" alt="Img" src={flipkart} />
                    <img className="img-2" alt="Img" src={microsoft} />
                    <img className="img-2" alt="Img" src={atlassian} />
                    <img className="img-2" alt="Img" src={figma} />
                    <img className="img-2" alt="Img" src={jira} />
                    <img className="img-2" alt="Img" src={licious} />
                    <img className="img-2" alt="Img" src={facebook} />
                    <img className="img-2" alt="Img" src={mastercard} />
                    <img className="img-2" alt="Img" src={ncrb} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={ncipc} />
                    <img className="img-2" alt="Img" src={yahoo} />
                    <img className="img-2" alt="Img" src={zomato} />
                    <img className="img-2" alt="Img" src={paytm} />
                    <img className="img-2" alt="Img" src={sony} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            src={darkeyeSnap}
            style={{ marginTop: "-5vw", position: "relative", width: "55vw" }}
          />
          <div className="cloudsecurity-services-section2">
            <div className="cloudsecurity-div-2">
              <button
                className="cloudsecurity-primary-btn-instance2"
                divclassName="cloudsecurity-design-component-instance-node"
                property1="tag"
              >
                Apni Sec / Cloud Watch
              </button>
              <div className="cloudsecurity-section1-text">
                Cloud Security Posture Management to identify and remediate
                security vulnerabilities. It ensures compliance with industry
                standards, detects misconfigurations, and enforces best
                practices. With automated alerts and actionable insights, CSPM
                helps prevent breaches and strengthens your cloud security
                posture. Protect your cloud environment with proactive,
                real-time monitoring.
              </div>
            </div>
          </div>

          <div className="cloudsecurity-report-section2">
            <div className="cloudsecurity-section2-heading">
              why Cloud Security Is Important?
            </div>
            <div className="cloudsecurity-report-component131">
              <div className="cloudsecurity-section-1">
                <div className="cloudsecurity-content-1">
                  <div className="cloudsecurity-text-wrapper-131">
                    Hybrid Cloud Structure
                  </div>

                  <p className="cloudsecurity-comprehensive131">
                    Multiple cloud services Accounts and resources left in open
                    vulnerable state without knowing
                  </p>
                </div>
              </div>

              <div className="cloudsecurity-section-2">
                <div className="cloudsecurity-content-1">
                  <div className="cloudsecurity-text-wrapper-131">
                    Misconfigurations
                  </div>

                  <p className="cloudsecurity-comprehensive131">
                    With Cloud services like AWS, Azure & GCP misconfiguration
                    of services are at just a click
                  </p>
                </div>
              </div>
              <div className="cloudsecurity-section-3">
                <div className="cloudsecurity-content-1">
                  <div className="cloudsecurity-text-wrapper-131">
                    compliance Alignment{" "}
                  </div>

                  <p className="cloudsecurity-comprehensive131">
                    Meeting regulatory requirements like GDPR, HIPAA, or PCI DSS
                    can be complex, especially in cross-border cloud
                    deployments.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cloudsecurity-report-section3">
            <div className="cloudsecurity-section3-heading">
              Comprehensive Protection at Your Fingertips
            </div>
            <div className="cloudsecurity-report-component131">
              <div className="cloudsecurity-section-1">
                <div className="cloudsecurity-content-1">
                  <img
                    className="cloudsecurity-socialIcon132"
                    alt="Twitter"
                    src={searchIcon}
                  />
                  <div className="cloudsecurity-text-wrapper-132">
                    24/7 Monitoring
                  </div>
                  <p className="cloudsecurity-comprehensive132">
                    Cloud watcher continuously monitor changes in cloud
                    resources for misconfigurations
                  </p>
                </div>
                <div className="cloudsecurity-content-1">
                  <img
                    className="cloudsecurity-socialIcon132"
                    alt="Twitter"
                    src={shieldFlashIcon}
                  />
                  <div className="cloudsecurity-text-wrapper-132">
                    Identity and Access Management (IAM)
                  </div>
                  <p className="cloudsecurity-comprehensive132">
                    Implement robust IAM solutions, including Zero Trust
                    architectures, multi-factor authentication (MFA), and least
                    privilege access to mitigate credential theft and
                    over-permissioning risks.
                  </p>
                </div>
              </div>

              <div className="cloudsecurity-section-2">
                <div className="cloudsecurity-content-1">
                  <img
                    className="cloudsecurity-socialIcon132"
                    alt="Twitter"
                    src={notifyIcon}
                  />
                  <div className="cloudsecurity-text-wrapper-132">
                    Data Security
                  </div>
                  <p className="cloudsecurity-comprehensive132">
                    Cloud Watcher data loss prevention (DLP) and ensure robust
                    access controls to prevent unauthorized data leakage.
                  </p>
                </div>
                <div className="cloudsecurity-content-1">
                  <img
                    className="cloudsecurity-socialIcon132"
                    alt="Twitter"
                    src={fileChartIcon}
                  />
                  <div className="cloudsecurity-text-wrapper-132">
                    Cloud Attack Simulation
                  </div>
                  <p className="cloudsecurity-comprehensive132">
                    Identifying new threats targeted at your business before
                    they escalate.
                  </p>
                </div>
              </div>
              <div className="cloudsecurity-section-3">
                <div className="cloudsecurity-content-1">
                  <img
                    className="cloudsecurity-socialIcon132"
                    alt="Twitter"
                    src={nodeIcon}
                  />
                  <div className="cloudsecurity-text-wrapper-132">
                    Threat Detection and Incident Response
                  </div>
                  <p className="cloudsecurity-comprehensive132">
                    Deploy threat intelligence platforms to identify advanced
                    threats and support 24/7 Security Operations Center (SOC)
                    services
                  </p>
                </div>
                <div className="cloudsecurity-content-1">
                  <img
                    className="cloudsecurity-socialIcon132"
                    alt="Twitter"
                    src={dbIcon}
                  />
                  <div className="cloudsecurity-text-wrapper-132">
                    Compliance Support
                  </div>
                  <p className="cloudsecurity-comprehensive132">
                    Offer automated tools for regulatory adherence, such as
                    compliance audits, reporting, and templates for standards
                    like GDPR, HIPAA, and PCI DSS.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cloudsecurity-stats-section-1">
            <div className="cloudsecurity-div-3">
              <div className="cloudsecurity-frame-15">
                <div className="cloudsecurity-text-wrapper-141">900+</div>

                <div className="cloudsecurity-text-wrapper-301">
                  Pipelines Secured
                </div>
              </div>

              <div className="cloudsecurity-frame-15">
                <div className="cloudsecurity-element">15K+</div>
                <div className="cloudsecurity-text-wrapper-301">
                  Assets Monitored
                </div>
              </div>
              <div className="cloudsecurity-frame-15">
                <div className="cloudsecurity-text-wrapper-141">1k+</div>

                <div className="cloudsecurity-text-wrapper-301">
                  BBP Reports Closed
                </div>
              </div>

              <div className="cloudsecurity-frame-15">
                <div className="cloudsecurity-text-wrapper-141">300Bn+</div>

                <div className="cloudsecurity-text-wrapper-301">
                  Data Scraped
                </div>
              </div>
            </div>
          </div>

          <div className="cloudsecurity-how-we-do-it-section">
            <div className="cloudsecurity-title-2">
              <div className="cloudsecurity-overlap-group-6">
                <img className="cloudsecurity-line-5" alt="Line" src={line74} />

                <div className="cloudsecurity-how-we-do-it">How We Do It</div>
              </div>
            </div>

            <div className="cloudsecurity-w-rapper-wrapper">
              <div className="cloudsecurity-w-rapper">
                <div className="cloudsecurity-overlap-6">
                  <div className="cloudsecurity-frame-16">
                    <div className="cloudsecurity-content-2">
                      <div className="cloudsecurity-img-wrapper">
                        <img
                          className="cloudsecurity-watcher"
                          alt="Frame"
                          src={searchIcon}
                        />
                      </div>

                      <p className="cloudsecurity-text-wrapper-32">
                        Define unique keywords, domain list, Third Party
                        Dashboards of organization
                      </p>
                    </div>

                    <div className="cloudsecurity-content-3">
                      <div className="cloudsecurity-img-wrapper">
                        <img
                          className="cloudsecurity-watcher"
                          alt="Watcher"
                          src={dbIcon}
                        />
                      </div>

                      <p className="cloudsecurity-text-wrapper-32">
                        Advanced AI filters identify and prioritize working
                        Credentials and Leaks directly impacting the
                        organization
                      </p>
                    </div>

                    <div className="cloudsecurity-content-4">
                      <div className="cloudsecurity-img-wrapper">
                        <img
                          className="cloudsecurity-watcher"
                          alt="Report"
                          src={nodeIcon}
                        />
                      </div>

                      <p className="cloudsecurity-text-wrapper-32">
                        Persistent Monitoring and 24/7 Support for Detailed leak
                        analysis
                      </p>
                    </div>
                  </div>

                  <div className="cloudsecurity-frame-17">
                    <div className="cloudsecurity-content-5">
                      <div className="cloudsecurity-img-wrapper">
                        <img
                          className="cloudsecurity-watcher"
                          alt="Penetration testing"
                          src={shieldFlashIcon}
                        />
                      </div>

                      <p className="cloudsecurity-text-wrapper-32">
                        Continuous scanning of dark web forums, marketplaces,
                        and chat platforms.
                      </p>
                    </div>

                    <div className="cloudsecurity-content-6">
                      <div className="cloudsecurity-img-wrapper">
                        <img
                          className="cloudsecurity-watcher"
                          alt="Blockchain"
                          src={notifyIcon}
                        />
                      </div>

                      <p className="cloudsecurity-text-wrapper-32">
                        Instant alerts and Monthly reports sent to the team.
                      </p>
                    </div>
                  </div>

                  <div className="cloudsecurity-group-13">
                    <div className="cloudsecurity-overlap-6">
                      <div className="cloudsecurity-lines">
                        <div className="cloudsecurity-overlap-group-7">
                          <img
                            className="cloudsecurity-line-6"
                            alt="Line"
                            src={line79}
                          />

                          <img
                            className="cloudsecurity-line-7"
                            alt="Line"
                            src={line81}
                          />

                          <img
                            className="cloudsecurity-line-8"
                            alt="Line"
                            src={line83}
                          />
                        </div>

                        <img
                          className="cloudsecurity-line-9"
                          alt="Line"
                          src={line80}
                        />

                        <img
                          className="cloudsecurity-line-10"
                          alt="Line"
                          src={line82}
                        />

                        <img
                          className="cloudsecurity-line-11"
                          alt="Line"
                          src={line84}
                        />
                      </div>

                      <div className="cloudsecurity-point">
                        <div className="cloudsecurity-timelineDot" />

                        <div className="cloudsecurity-timelineDot" />

                        <div className="cloudsecurity-timelineDot" />

                        <div className="cloudsecurity-timelineDot" />

                        <div className="cloudsecurity-timelineDot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cloudsecurity-about-section">
            <div className="cloudsecurity-about-component">
              <div className="cloudsecurity-col">
                <img
                  className="cloudsecurity-report-img"
                  alt="Report img"
                  src={reportImgSingle}
                />
              </div>

              <div className="cloudsecurity-col-2">
                <div className="cloudsecurity-div-4">
                  <div className="cloudsecurity-text-wrapper-36">
                    Feature Report
                  </div>
                  <div className="cloudsecurity-text-wrapper-37">
                    Comprehensive evaluations to identify vulnerabilities and
                    provide actionable insights for risk mitigation.
                  </div>
                  <div className="cloudsecurity-div-wrapper">
                    <div
                      className="cloudsecurity-text-wrapper-6"
                      onClick={() =>
                        window.open(
                          "https://assets.apnisec.com/public/Sample+Reports.zip",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Download Sample Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cloudsecurity-testimonial-section">
            <div className="cloudsecurity-testimonial-wrap">
              <Testimonies activePage="cloudsecurity" />
            </div>
          </div>

          <div className="cloudsecurity-team-section">
            <div className="cloudsecurity-text-wrapper-39">
              Frequently Asked Questions
            </div>

            <div
              style={{
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                width: "65vw",
              }}
            >
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #8BF0FB",
                    borderRadius: "5px",
                    marginBottom: "1vw",
                    backgroundColor: "rgba(139,240,251,0.1)",
                    padding: "1vw",
                  }}
                >
                  <div
                    onClick={() => toggleFAQ(index)}
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1vw",
                      cursor: "pointer",
                      fontSize: "1.25vw",
                    }}
                  >
                    {faq.question}
                    <span
                      style={{
                        transform:
                          activeIndex === index
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      ▼
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        padding: "2vw",
                        fontSize: "1vw",
                        color: "#333",
                        borderTop: "1px solid #c9f9c9",
                      }}
                    >
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="cloudsecurity-CTA-section">
            {/* <div className="cloudsecurity-background" /> */}
            <div className="cloudsecurity-networkLinesWrapper">
              {" "}
              <img
                className="cloudsecurity-networkLines"
                alt="Vector"
                src={networkLines}
              />
            </div>

            <div className="cloudsecurity-div-2">
              <div className="cloudsecurity-text-wrapper-40">
                Protect Your Data Now
              </div>
            </div>
            <div
              className="cloudsecurity-div-wrapper"
              onClick={() =>
                navigate("/contact-us", {
                  state: { activePage: "cloudsecurity" }, // Passing 'darkeye' as the value for activePage
                })
              }
            >
              <div className="cloudsecurity-text-wrapper-6">Get Quote</div>
            </div>
            <div className="cloudsecurity-networkLinesWrapper1">
              {" "}
              <img
                className="cloudsecurity-networkLines1"
                alt="Vector"
                src={networkLines}
              />
            </div>
          </div>
          <Footer />
        
        </div>
      </div>
    </div>
  );
}
