import "./ui.css";

export function Skeleton({ width, height }) {
  return (
    <div
      className="skeleton"
      style={{
        width,
        height,
      }}
    ></div>
  );
}