import React from "react";

const Button = ({ variant = "primary", children, onClick, style, isLoading }) => {
  const styles = {
    base: {
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
      fontSize: "1rem",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      transition: "background-color 0.3s, opacity 0.3s",
    },
    primary: {
      backgroundColor: "#00FFB2",
      color: "#0F1A1B",
    },
    secondary: {
      backgroundColor: "#6c757d",
      color: "#fff",
    },
    dark: {
      backgroundColor: "#343a40",
      color: "#fff",
    },
    darkBorder: {
      backgroundColor: "rgba(245, 255, 252, 0.05)",
      border: "1px solid rgba(245, 255, 252, 0.20)",
      color: "#F5FFFC",
      padding: "16px 30px",
    },
  };

  const combinedStyles = {
    ...styles.base,
    ...styles[variant],
    ...style,
  };

  return (
    <>
      <style>
      {`
         .button:hover {
            opacity: 0.8;
          }

          .button:disabled {
            cursor: not-allowed! important;
            opacity: 0.8;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          .loader {
            animation: spin 1s linear infinite;
          }
        `}
      </style>

      <button disabled={isLoading} className="button" style={combinedStyles} onClick={onClick}>
        {children}

        {isLoading && ( <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-loader loader"
          >
            <path d="M12 2v4" />
            <path d="m16.2 7.8 2.9-2.9" />
            <path d="M18 12h4" />
            <path d="m16.2 16.2 2.9 2.9" />
            <path d="M12 18v4" />
            <path d="m4.9 19.1 2.9-2.9" />
            <path d="M2 12h4" />
            <path d="m4.9 4.9 2.9 2.9" />
          </svg>)}
      </button>
    </>
  );
};

export default Button;
