import React from "react";
import { useNavigate, Link } from "react-router-dom";
import brandLogo from "../../pages/assests/logo/brand-logo.svg";
import startupIndia from "../../pages/assests/startupIndia.png";
import iso from "../../pages/assests/iso.svg";
import { SocialIconDark } from "./SocialIcon";

export default function FooterDark() {
  const navigate = useNavigate();

  const handleNavigate = (path, hash) => {
    navigate(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <footer
      style={{
        backgroundColor: "rgba(1, 14, 10, 1)",
        opacity: "99%",
        color: "white",
        padding: "77px",
      }}
    >
      <style>
        {`
          @media (max-width: 768px) {
            .footer-container {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
            .footer-section {
              width: 100%;
              margin-bottom: 20px;
            }
            .footer-section img {
              width: 50%;
            }
            .footer-social-icons {
              justify-content: center;
            }
            .footer-links {
              flex-direction: column;
              align-items: center;
            }
            .footer-links div {
              margin-bottom: 10px;
            }
          }
        `}
      </style>
      <div
        className="footer-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div
          className="footer-section"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "start",
            gap: "10px",
          }}
        >
          <img src={brandLogo} style={{ width: "15vw" }} />
          <p style={{ fontSize: "1vw" }}>WE ARE CERTIFIED</p>
          <div>
            <img src={startupIndia} style={{ width: "10vw" }} />
            <img src={iso} style={{ width: "4vw" }} />
          </div>
          <p>© 2025 Apni Sec. All rights reserved.</p>
        </div>

        <div
          className="footer-section"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          {/* social icons */}
          <SocialIconDark />

          {/* company and services */}
          <div
            className="footer-links"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "65px",
            }}
          >
            <div>
              <p
                style={{
                  color: "#F5FFFC",
                  fontSize: "1.8rem",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Company
              </p>

              <div>
                <p
                  style={style.link}
                  onClick={() => handleNavigate("/", "")}
                >
                  Home
                </p>

                <div style={style.link}
                onClick={() => handleNavigate("/cloud-security", "")}
                
                >Solutions</div>

                <div style={style.link}
                 onClick={() => handleNavigate("/", "process")}
                > Process</div>

                <div
                  style={style.link}
                  onClick={() => handleNavigate("/", "report")}
                >
                  Report
                </div>

                <div style={style.link}
                  onClick={() => handleNavigate("/", "services")}
                  >
                  Services
                </div>
              </div>
            </div>

            <div>
              <p
                style={{
                  color: "#F5FFFC",
                  fontSize: "1.8rem",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Services
              </p>

              <div>
                <p
                  style={style.link}
                  onClick={() => handleNavigate("/dark-eye-watcher", "")}
                >
                  Dark Eye Watcher
                </p>

                <div
                  style={style.link}
                  onClick={() => handleNavigate("/cloud-security", "")}
                >
                  Cloud Security
                </div>

                <div
                  style={style.link}
                  onClick={() => handleNavigate("/vciso", "")}
                >
                  Virtual CISO
                </div>

                <div
                  style={style.link}
                  onClick={() => handleNavigate("/red-team-assesment", "")}
                >
                  Red Team Assessment
                </div>

                <div
                  style={style.link}
                  onClick={() => handleNavigate("/vapt", "")}
                >
                  VAPT
                </div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "right", color: "#F5FFFC" }}>
            Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms And Services
          </div>
        </div>
      </div>
    </footer>
  );
}

const style = {
  link: {
    color: "#F5FFFC",
    opacity: "0.8",
    fontSize: "1.5rem",
    margin: "0px",
    padding: "0px",
    cursor: "pointer",
  },
};