import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg";
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png";
import tushar from "./assests/tushar.png";
import rajat from "./assests/rajat.jpg";
import shield from "./assests/shield.svg";
import networkLines from "./assests/networkLines.svg";
import brandLogo from "./assests/brand-logo-dk.svg";
import certified from "./assests/certified.svg";
import linkedin from "./assests/linkedin.svg";
import twitter from "./assests/twitter.svg";
import youtube from "./assests/youtube.svg";
import heroBrands from "./assests/heroBrands.svg";
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg";
import reportImg from "./assests/report-img.png";
import reportImgSingle from "./assests/report-img-single.png";
import "../styleguide.css";
import vector from "./assests/vector.svg";
import apple from "./assests/appreciationLogos/apple.svg";
import google from "./assests/appreciationLogos/google.svg";
import amazon from "./assests/appreciationLogos/amazon.svg";
import microsoft from "./assests/appreciationLogos/microsoft.svg";
import asus from "./assests/appreciationLogos/asus.svg";
import redbull from "./assests/appreciationLogos/redbull.svg";
import dell from "./assests/appreciationLogos/dell.svg";
import dominos from "./assests/appreciationLogos/dominos.svg";
import figma from "./assests/appreciationLogos/figma.svg";
import flipkart from "./assests/appreciationLogos/flipkart.svg";
import atlassian from "./assests/appreciationLogos/atlassian.svg";
import jira from "./assests/appreciationLogos/jira.svg";
import licious from "./assests/appreciationLogos/licious.svg";
import facebook from "./assests/appreciationLogos/facebook.svg";
import mastercard from "./assests/appreciationLogos/mastercard.svg";
import ncrb from "./assests/appreciationLogos/ncrb.svg";
import ncipc from "./assests/appreciationLogos/ncipc.svg";
import yahoo from "./assests/appreciationLogos/yahoo.svg";
import zomato from "./assests/appreciationLogos/zomato.svg";
import paytm from "./assests/appreciationLogos/paytm.svg";
import sony from "./assests/appreciationLogos/sony.svg";
import fileSearchIcon from "./assests/fileSearchIcon.svg";
import crosshairIcon from "./assests/crosshairIcon.svg";
import eyelineIcon from "./assests/eyelineIcon.svg";
import shieldIcon from "./assests/shieldIcon.svg";
import fileChartIcon from "./assests/fileChartIcon.svg";
import eyeline from "./assests/eyeline.svg";
import shieldGreenIcon from "./assests/shieldGreenIcon.svg";
import rupeeIcon from "./assests/rupeeIcon.svg";
import iso from "./assests/iso.svg";
import startupIndia from "./assests/startupIndia.png";
import "./RedTeamAssesmentStyle.css";
import "../styleguide.css";
import watcherImg from "./assests/WatcherDashboard.svg";
import darkeyeSnap from "./assests/darkeyeSnap.svg";
import dbIcon from "./assests/darkEyeIcons/dbIcon.svg";
import fileIcon from "./assests/darkEyeIcons/fileIcon.svg";
import nodeIcon from "./assests/darkEyeIcons/nodeIcon.svg";
import notifyIcon from "./assests/darkEyeIcons/notifyIcon.svg";
import searchIcon from "./assests/darkEyeIcons/searchIcon.svg";
import shieldFlashIcon from "./assests/darkEyeIcons/shieldFlashIcon.svg";
import Navbar from "../components/Navbar";
import Testimonies from "../components/Testimonies/Testimonies";
import Footer from "../components/Footer";

export default function RedTeamAssesment() {
  const faqData = [
    {
      question: "How often are alerts generated?",
      answer:
        "Alerts are generated based on specific conditions or triggers, which can vary depending on the system.",
    },
    {
      question: "What is the average response time?",
      answer:
        "The average response time is typically under 5 minutes, depending on the complexity of the issue.",
    },
    {
      question: "Can I customize the alerts?",
      answer:
        "Yes, you can customize alerts based on your preferences and system configurations.",
    },
    {
      question: "What platforms are supported?",
      answer:
        "Our service supports Windows, macOS, Linux, and mobile platforms like iOS and Android.",
    },
    {
      question: "Is there a trial period available?",
      answer: "Yes, we offer a 14-day free trial with access to all features.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us"); // Redirect to /contact-us
  };

  const handleLogoClick = () => {
    navigate("/"); // Redirect to /contact-us
  };
  return (
    <div className="website-layer">
      <div className="redteam-website-v-layer">
        <div className="redteam-main-wrapper">
          <Navbar activePage="redteamassesment" />
          <div className="redteam-hero-section">
            <div className="redteam-hero-section-2">
              <div className="redteam-hero-header">
                <p className="redteam-defend-against-cyber">
                  <span className="redteam-span">Red Team Asssesment</span>
                </p>

                <p className="redteam-p">
                  Evaluate Overall weaklinks in your systems, processes and
                  network
                </p>
              </div>

              <div>
                <div
                  className="redteam-div-wrapper"
                  onClick={() =>
                    navigate("/contact-us", {
                      state: { activePage: "redteamassesment" }, // Passing 'darkeye' as the value for activePage
                    })
                  }
                >
                  <div className="redteam-text-wrapper-6">Get Quote</div>
                </div>
              </div>

              <div className="redteam-trust">
                <div className="redteam-overall-rating">
                <div className="frame" style={{display: "flex", flexDirection: "column", gap: "0px"}}>
                    <img
                      className="img"
                      alt="overallRating"
                      src={overallRating}
                    />
                    <p style={{fontSize: "1vw", color: "white", margin: "0px", fontWeight: "600"}}>We secure 1000+ Organizations</p>
                  </div>
                </div>

                <div className="redteam-frame-2">
                  <div className="image-slider">
                    <img className="img-2" alt="Img" src={asus} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={amazon} />
                    <img className="img-2" alt="Img" src={redbull} />
                    <img className="img-2" alt="Img" src={dell} />
                    <img className="img-2" alt="Img" src={apple} />
                    <img className="img-2" alt="Img" src={dominos} />
                    <img className="img-2" alt="Img" src={flipkart} />
                    <img className="img-2" alt="Img" src={microsoft} />
                    <img className="img-2" alt="Img" src={atlassian} />
                    <img className="img-2" alt="Img" src={figma} />
                    <img className="img-2" alt="Img" src={jira} />
                    <img className="img-2" alt="Img" src={licious} />
                    <img className="img-2" alt="Img" src={facebook} />
                    <img className="img-2" alt="Img" src={mastercard} />
                    <img className="img-2" alt="Img" src={ncrb} />
                    <img className="img-2" alt="Img" src={google} />
                    <img className="img-2" alt="Img" src={ncipc} />
                    <img className="img-2" alt="Img" src={yahoo} />
                    <img className="img-2" alt="Img" src={zomato} />
                    <img className="img-2" alt="Img" src={paytm} />
                    <img className="img-2" alt="Img" src={sony} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            src={darkeyeSnap}
            style={{ marginTop: "-5vw", position: "relative", width: "55vw" }}
          />
          <div className="redteam-services-section2">
            <div className="redteam-div-2">
              <button
                className="redteam-primary-btn-instance2"
                divclassName="redteam-design-component-instance-node"
                property1="tag"
              >
                Apni Sec / Red team assessment
              </button>
              <div className="redteam-section1-text">
                realistic simulation of cyberattacks designed to test an
                organization's security defenses. Ethical hackers emulate
                adversaries, targeting systems, networks, and personnel to
                uncover vulnerabilities and exploit gaps. The exercise evaluates
                the effectiveness of detection, response, and mitigation
                capabilities. Insights from the assessment help strengthen
                defenses, improve incident response, and build overall
                resilience against real-world threats.
              </div>
            </div>
          </div>

          <div className="redteam-report-section2">
            <div className="redteam-section2-heading">
              Why Blue Team isn’t Enough?
            </div>
            <div className="redteam-report-component131">
              <div className="redteam-section-1">
                <div className="redteam-content-1">
                  <div className="redteam-text-wrapper-131">
                    Adversarial Perspective
                  </div>

                  <p className="redteam-comprehensive131">
                    Blue Teams often lack the attacker’s mindset, making it
                    harder to predict sophisticated tactics and identify
                    overlooked vulnerabilities.
                  </p>
                </div>
              </div>

              <div className="redteam-section-2">
                <div className="redteam-content-1">
                  <div className="redteam-text-wrapper-131">
                    Evolving Threat Landscape
                  </div>

                  <p className="redteam-comprehensive131">
                    New and advanced threats require continuous testing, which a
                    Blue Team alone may not effectively simulate
                  </p>
                </div>
              </div>
              <div className="redteam-section-3">
                <div className="redteam-content-1">
                  <div className="redteam-text-wrapper-131">
                    real world attack Scenarios
                  </div>

                  <p className="redteam-comprehensive131">
                    Blue Teams may only defend against known attack patterns and
                    tactics. A Red Team can simulate evolving and unknown
                    attacks, helping the organization identify weaknesses that
                    have not been discovered yet.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="redteam-report-section3">
            <div className="redteam-section3-heading">
              Comprehensive Protection at Your Fingertips
            </div>
            <div className="redteam-report-component131">
              <div className="redteam-section-1">
                <div className="redteam-content-1">
                  <img
                    className="redteam-socialIcon132"
                    alt="Twitter"
                    src={searchIcon}
                  />
                  <div className="redteam-text-wrapper-132">
                    On Site Audits{" "}
                  </div>
                  <p className="redteam-comprehensive132">
                    Onsite Firewall and network configuration audits to figure
                    out loopholes in the system
                  </p>
                </div>
                <div className="redteam-content-1">
                  <img
                    className="redteam-socialIcon132"
                    alt="Twitter"
                    src={shieldFlashIcon}
                  />
                  <div className="redteam-text-wrapper-132">
                    Threat Exposure
                  </div>
                  <p className="redteam-comprehensive132">
                    Alert and assess new endpoints or dynamic parameters
                    introduced in hot-fixes
                  </p>
                </div>
              </div>

              <div className="redteam-section-2">
                <div className="redteam-content-1">
                  <img
                    className="redteam-socialIcon132"
                    alt="Twitter"
                    src={notifyIcon}
                  />
                  <div className="redteam-text-wrapper-132">
                    AI-Powered Alerts
                  </div>
                  <p className="redteam-comprehensive132">
                    Advanced algorithms detect and notify you about suspicious
                    activity.
                  </p>
                </div>
                <div className="redteam-content-1">
                  <img
                    className="redteam-socialIcon132"
                    alt="Twitter"
                    src={fileChartIcon}
                  />
                  <div className="redteam-text-wrapper-132">
                    DeepFake campaigns
                  </div>
                  <p className="redteam-comprehensive132">
                    Identifying new Deepfake campaigns targeted at your business
                    before they escalate.
                  </p>
                </div>
              </div>
              <div className="redteam-section-3">
                <div className="redteam-content-1">
                  <img
                    className="redteam-socialIcon132"
                    alt="Twitter"
                    src={nodeIcon}
                  />
                  <div className="redteam-text-wrapper-132">Compliance</div>
                  <p className="redteam-comprehensive132">
                    Check for resources to be ISO, GDPR, PCI HIPAA compliant
                    across organization
                  </p>
                </div>
                <div className="redteam-content-1">
                  <img
                    className="redteam-socialIcon132"
                    alt="Twitter"
                    src={dbIcon}
                  />
                  <div className="redteam-text-wrapper-132">
                    Social Engineering Simulations
                  </div>
                  <p className="redteam-comprehensive132">
                    Real World Phishing, Vishing campaigns with critical
                    Finance, Admin teams and Higher Executives
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="redteam-stats-section-1">
            <div className="redteam-div-3">
              <div className="redteam-frame-15">
                <div className="redteam-text-wrapper-141">50k+</div>

                <div className="redteam-text-wrapper-301">
                  Simulation Attacks
                </div>
              </div>

              <div className="redteam-frame-15">
                <div className="redteam-element">15K+</div>
                <div className="redteam-text-wrapper-301">Assets Monitored</div>
              </div>
              <div className="redteam-frame-15">
                <div className="redteam-text-wrapper-141">900TB+</div>

                <div className="redteam-text-wrapper-301">Data Indexed</div>
              </div>

              <div className="redteam-frame-15">
                <div className="redteam-text-wrapper-141">99.99%</div>

                <div className="redteam-text-wrapper-301">
                  Threat Mitigation
                </div>
              </div>
            </div>
          </div>

          <div className="redteam-how-we-do-it-section">
            <div className="redteam-title-2">
              <div className="redteam-overlap-group-6">
                <img className="redteam-line-5" alt="Line" src={line74} />

                <div className="redteam-how-we-do-it">How We Do It</div>
              </div>
            </div>

            <div className="redteam-w-rapper-wrapper">
              <div className="redteam-w-rapper">
                <div className="redteam-overlap-6">
                  <div className="redteam-frame-16">
                    <div className="redteam-content-2">
                      <div className="redteam-img-wrapper">
                        <img
                          className="redteam-watcher"
                          alt="Frame"
                          src={searchIcon}
                        />
                      </div>

                      <p className="redteam-text-wrapper-32">
                        Define unique keywords, domain list, Third Party
                        Dashboards of organization
                      </p>
                    </div>

                    <div className="redteam-content-3">
                      <div className="redteam-img-wrapper">
                        <img
                          className="redteam-watcher"
                          alt="Watcher"
                          src={dbIcon}
                        />
                      </div>

                      <p className="redteam-text-wrapper-32">
                        Advanced AI filters identify and prioritize working
                        Credentials and Leaks directly impacting the
                        organization
                      </p>
                    </div>

                    <div className="redteam-content-4">
                      <div className="redteam-img-wrapper">
                        <img
                          className="redteam-watcher"
                          alt="Report"
                          src={nodeIcon}
                        />
                      </div>

                      <p className="redteam-text-wrapper-32">
                        Persistent Monitoring and 24/7 Support for Detailed leak
                        analysis
                      </p>
                    </div>
                  </div>

                  <div className="redteam-frame-17">
                    <div className="redteam-content-5">
                      <div className="redteam-img-wrapper">
                        <img
                          className="redteam-watcher"
                          alt="Penetration testing"
                          src={shieldFlashIcon}
                        />
                      </div>

                      <p className="redteam-text-wrapper-32">
                        Continuous scanning of dark web forums, marketplaces,
                        and chat platforms.
                      </p>
                    </div>

                    <div className="redteam-content-6">
                      <div className="redteam-img-wrapper">
                        <img
                          className="redteam-watcher"
                          alt="Blockchain"
                          src={notifyIcon}
                        />
                      </div>

                      <p className="redteam-text-wrapper-32">
                        Instant alerts and Monthly reports sent to the team.
                      </p>
                    </div>
                  </div>

                  <div className="redteam-group-13">
                    <div className="redteam-overlap-6">
                      <div className="redteam-lines">
                        <div className="redteam-overlap-group-7">
                          <img
                            className="redteam-line-6"
                            alt="Line"
                            src={line79}
                          />

                          <img
                            className="redteam-line-7"
                            alt="Line"
                            src={line81}
                          />

                          <img
                            className="redteam-line-8"
                            alt="Line"
                            src={line83}
                          />
                        </div>

                        <img
                          className="redteam-line-9"
                          alt="Line"
                          src={line80}
                        />

                        <img
                          className="redteam-line-10"
                          alt="Line"
                          src={line82}
                        />

                        <img
                          className="redteam-line-11"
                          alt="Line"
                          src={line84}
                        />
                      </div>

                      <div className="redteam-point">
                        <div className="redteam-timelineDot" />

                        <div className="redteam-timelineDot" />

                        <div className="redteam-timelineDot" />

                        <div className="redteam-timelineDot" />

                        <div className="redteam-timelineDot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="redteam-about-section">
            <div className="redteam-about-component">
              <div className="redteam-col">
                <img
                  className="redteam-report-img"
                  alt="Report img"
                  src={reportImgSingle}
                />
              </div>

              <div className="redteam-col-2">
                <div className="redteam-div-4">
                  <div className="redteam-text-wrapper-36">Feature Report</div>
                  <div className="redteam-text-wrapper-37">
                    Comprehensive evaluations to identify Server and Client
                    vulnerabilities with easy to understand Steps of
                    reproduction and Proof of Concept.
                  </div>
                  <div className="redteam-div-wrapper">
                    <div
                      className="cloudsecurity-text-wrapper-6"
                      onClick={() =>
                        window.open(
                          "https://assets.apnisec.com/public/Sample+Reports.zip",
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Download Sample Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="redteam-testimonial-section">
            <div className="redteam-testimonial-wrap">
              <Testimonies activePage="redteam" />
            </div>
          </div>

          <div className="redteam-team-section">
            <div className="redteam-text-wrapper-39">
              Frequently Asked Questions
            </div>

            <div
              style={{
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                width: "65vw",
              }}
            >
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #ffabab",
                    borderRadius: "5px",
                    marginBottom: "1vw",
                    backgroundColor: "rgba(255,171,171,0.1)",
                    padding: "1vw",
                  }}
                >
                  <div
                    onClick={() => toggleFAQ(index)}
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1vw",
                      cursor: "pointer",
                      fontSize: "1.25vw",
                    }}
                  >
                    {faq.question}
                    <span
                      style={{
                        transform:
                          activeIndex === index
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      ▼
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        padding: "2vw",
                        fontSize: "1vw",
                        color: "#333",
                        borderTop: "1px solid rgba(255, 171, 171, 0.2)",
                      }}
                    >
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="redteam-CTA-section">
            {/* <div className="redteam-background" /> */}
            <div className="redteam-networkLinesWrapper">
              {" "}
              <img
                className="redteam-networkLines"
                alt="Vector"
                src={networkLines}
              />
            </div>

            <div className="redteam-div-2">
              <div className="redteam-text-wrapper-40">
                Protect Your Data Now
              </div>
            </div>
            <div
              className="redteam-div-wrapper"
              onClick={() =>
                navigate("/contact-us", {
                  state: { activePage: "redteam" }, // Passing 'darkeye' as the value for activePage
                })
              }
            >
              <div className="redteam-text-wrapper-6">Get Quote</div>
            </div>
            <div className="redteam-networkLinesWrapper1">
              {" "}
              <img
                className="redteam-networkLines1"
                alt="Vector"
                src={networkLines}
              />
            </div>
          </div>
          <Footer />
        
        </div>
      </div>
    </div>
  );
}
