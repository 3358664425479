import React, { useEffect, useState } from "react";
import networkLines from "./assests/networkLines.svg";
import networkLinesRed from "./assests/networkLinesRed.svg";
import { Skeleton } from "../components/ui/Skeleton";
import FeatureReport from "../components/dark-eye/FeatureReport";
import account from "../pages/assests/darkEye/account.svg";
import cookie from "../pages/assests/darkEye/cookie.svg";
import file from "../pages/assests/darkEye/file.svg";
import database from "../pages/assests/darkEye/database.svg";
import globalSearch from "../pages/assests/darkEye/global-search.svg";
import padlock from "../pages/assests/darkEye/padlock.svg";

import user from "../pages/assests/darkEye/user.svg";
import cookieRed from "../pages/assests/darkEye/cookieRed.svg";
import documentRed from "../pages/assests/darkEye/document-red.svg";
import databaseRed from "../pages/assests/darkEye/databaseRed.svg";
import globalSearchRed from "../pages/assests/darkEye/global-search-red.svg";
import padlockRed from "../pages/assests/darkEye/padlock-red.svg";
import Button from "../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../components/notification/NotificationContext";

const iconMap = {
  "Compromised Corporate Accounts": account,
  Cookies: cookie,
  "Dark Web Mentions": globalSearch,
  "Database Files": database,
  "Documents Leaked": file,
  Passwords: padlock,
};
const iconMapRed = {
  "Compromised Corporate Accounts": user,
  Cookies: cookieRed,
  "Dark Web Mentions": globalSearchRed,
  "Database Files": databaseRed,
  "Documents Leaked": documentRed,
  Passwords: padlockRed,
};

export default function DarkEyeReport({
  reportData,
  domain,
  setSwitchToReport,
}) {
  if (reportData === null) {
    return (
      <div
        style={{
          width: "100vw",
          backgroundColor: "#0F1A1B",
          color: "#00ffb2",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "50px",
        }}
      >
        <GetReportHeading />
        <div className="darkeye-CTA-section">
          <div className="networkLinesWrapper" style={{ height: "auto" }}>
            <img
              className="networkLines"
              alt="Vector"
              src={networkLines}
              style={{ transform: "rotate(90deg)" }}
            />
          </div>

          <Skeleton width={"40vw"} height={"20px"} />

          <div className="networkLinesWrapper" style={{ height: "auto" }}>
            <img
              className="networkLines"
              alt="Vector"
              src={networkLines}
              style={{ transform: "rotate(-90deg)" }}
            />
          </div>
        </div>

        <div className="darkeye-CTA-section">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "5rem",
            }}
          >
            <Skeleton width={"250px"} height={"185px"} />
            <Skeleton width={"250px"} height={"185px"} />
            <Skeleton width={"250px"} height={"185px"} />
          </div>
        </div>

        <div className="darkeye-CTA-section">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "5rem",
            }}
          >
            <Skeleton width={"250px"} height={"185px"} />
            <Skeleton width={"250px"} height={"185px"} />
            <Skeleton width={"250px"} height={"185px"} />
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Skeleton width={"40vw"} height={"20px"} />
        </div>
      </div>
    );
  }


  useEffect(() => {
    const timer = setTimeout(() => {
      
      if (reportData === null) {
        // addNotification("Something went wrong. Please try again later", "error");
        setSwitchToReport(false);
      }
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  const reportValue =  Object.values(reportData).reduce(
    (acc, value) => acc + value,
    0
  );

  const renderReportCards = () => {
    return Object.entries(reportData).map(([key, value]) => (
      <div
        key={key}
        className="responsive-card"
        style={{
          ...cardStyle,
          border:
            reportValue > 0
              ? "1px solid rgba(255, 51, 51, 0.20)"
              : "1px solid rgba(0, 255, 178, 0.20)",
          background:
            reportValue > 0
              ? "rgba(255, 51, 51, 0.02)"
              : "rgba(245, 255, 252, 0.05)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              margin: "0px",
              padding: "0px",
              color: reportValue > 0 ? "#FF3333" : "#00FFB2",
              fontSize: "2rem",
            }}
          >
            {value}
          </p>

          <img
            src={reportValue > 0 ? iconMapRed[key] : iconMap[key]}
            alt={key}
            style={{ width: "50px", height: "50px" }}
          />
        </div>

        <p style={textStyle}>{key}</p>
      </div>
    ));
  };

  return (
    <div
      style={{ width: "100vw", backgroundColor: "#0F1A1B", color: "#00ffb2" }}
    >
      <div style={{ width: "100%", padding: "10px", paddingBottom: "40px" }}>
        <GetReportHeading />
      </div>

      <style>
        {`
          @media (max-width: 768px) {
            .responsive-card {
              width: 100%;
              height: auto;
              padding: 1.2rem;
              font-size: 30px;
            }
          }
          @media (min-width: 769px) and (max-width: 1024px) {
            .responsive-card {
              width: calc(50% - 1rem);
            }
          }
          @media (min-width: 1025px) {
            .responsive-card {
              width: calc(33.33% - 1rem);
            }
          }
           
        `}
      </style>

      {reportValue > 0 ? (
        // red alert
        <div className="darkeye-CTA-section">
          <div className="networkLinesWrapper" style={{ height: "auto" ,padding: "50px"}}>
            <img
              alt="Vector"
              src={networkLinesRed}
              style={{ transform: "rotate(180deg)" }}
            />
          </div>

          <h1 style={{ color: "#FF3333" }}>You are At Danger !</h1>

          <div className="networkLinesWrapper" style={{ height: "auto" }}>
            <img alt="Vector" src={networkLinesRed} />
          </div>
        </div>
      ) : (
        // green alert
        <div className="darkeye-CTA-section" style={{ height: "auto" , }}>
          <div className="networkLinesWrapper" style={{ height: "auto" }}>
            <img
              className="networkLines"
              alt="Vector"
              src={networkLines}
              style={{ transform: "rotate(90deg)" }}
            />
          </div>

          <h1 style={{ color: "#00ffb2" }}>You are Safe!</h1>

          <div className="networkLinesWrapper" style={{ height: "auto" }}>
            <img
              className="networkLines"
              alt="Vector"
              src={networkLines}
              style={{ transform: "rotate(-90deg)" }}
            />
          </div>
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "2.1rem",
            width: "70%",
          }}
        >
          {renderReportCards()}
        </div>
      </div>

      <p
        style={{
          color: "#F5FFFC",
          fontSize: "1.5rem",
          opacity: "50%",
          textAlign: "center",
        }}
      >
        {reportValue > 0
          ? ""
          : "No Breached Data Found!"}
      </p>

      <FeatureReport domain={domain} />
    </div>
  );
}

const cardStyle = {
  width: "255px",
  height: "125px",

  padding: "50px",
  fontSize: "40px",
  borderRadius: "4px",
};

const textStyle = {
  color: "white",
  fontSize: "20px",
};

function GetReportHeading() {
  const navigate = useNavigate();

  const handleNavigate = (path, hash) => {
    navigate(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <div
      style={{
        paddingTop: "50px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "100px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
        <p
          style={{
            padding: "0px",
            margin: "0px",
            color: "#F5FFFC",
            fontSize: "2rem",
          }}
        >
          Get Your Free DarkEye Report Now.
        </p>
        <p
          style={{
            padding: "0px",
            margin: "0px",
            color: "#F5FFFC",
            opacity: "0.5",
            fontSize: "2rem",
          }}
        >
          On your Dark Web Threat Exposure
        </p>
      </div>
      <Button
        onClick={() => handleNavigate("/dark-eye-watcher", "web-report")}
        variant="darkBorder"
      >
        Get My Report
      </Button>
    </div>
  );
}
