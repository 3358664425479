import { useState } from "react";
import Button from "../ui/Button";
import Input from "../ui/Input";
import { DarkAlert, Info, Success, Warning } from "../notification/Alert";

const FeatureReport = ({domain}) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    domain: domain,
    organizationName: "",
  });
  const [isLoading, setLaoding] = useState(false);

  const [alert, setAlert] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function handleSubmit() {
    // Form validation
    if (!data.name || !data.email  || !data.organizationName) {
      // setAlert(<Warning text="All fields are required." />);
      setAlert(<DarkAlert text="All fields are required." />);

      setTimeout(() => {
        setAlert(null);
      }, 10000);
      return;
    }

    try {
      setLaoding(true);
      const response = await fetch(
        "https://api.apnisec.com/darkeye-email-report",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (response.status === 200) {
        // setAlert(<Success text={result.message} />);
        setAlert(<DarkAlert text={result.message} />);

        setIsSubmitted(true);
      } else if (response.status === 400) {
        // setAlert(<Warning text={result.message} />);
        setAlert(<DarkAlert text={result.message} />);

      } else if (response.status === 429) {
        // setAlert(<Info text={ result.error} />);
        setAlert(<DarkAlert text={result.error} />);


      } else {
        // setAlert(<Warning text="Something went wrong" />);
        setAlert(<DarkAlert text={"Something went wrong"} />);

      }

      if(response.status !==200) {
        setTimeout(() => {
          setAlert(null);
        }, 5000);

      }


    } catch (error) {
      console.error("Error:", error);
      // setAlert(<Warning text="Something went wrong" />);
      setAlert(<DarkAlert text={"Something went wrong"} />);
      
      setTimeout(() => {
        setAlert(null);
      }, 5000);
    }
    finally{
      setLaoding(false);
    }
  }

  return (
    <div
      style={{
        paddingTop: "5rem",
        paddingBottom: "5rem",
        backgroundColor: "#0F1A1B",
        color: "white",
      }}
      id="web-report"
    >
      <style>{`
          @media (min-width: 768px) {
            .innerContainer { padding: 96px 16px; }
            .grid { grid-template-columns: 1fr 1fr; align-items: center; }
            .heading { font-size: 2.5rem; }
          }
          @media (max-width: 767px) {
            .grid { grid-template-columns: 1fr; }
            .heading { font-size: 2rem; }
          }
          .button:hover { background-color: #059669; }
          input::placeholder { color: #4b5563; }
        `}</style>

      <div
        className="innerContainer"
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "48px 16px",
          paddingBottom: "50px",
        }}
      >
        <div className="grid" style={{ display: "grid", gap: "48px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "0px" }}>
            <p
              className="heading"
              style={{
                color: "#F5FFFC",
                fontSize: "2rem",
                padding: "0px",
                margin: "0px",
              }}
            >
              Feature Report
            </p>

            <p
              style={{
                color: "#F5FFFC",
                opacity: "0.6",
                padding: "0px",
                margin: "0px",
                fontSize: "1rem",
              }}
            >
              Comprehensive Evaluations To Identify Vulnerabilities And Provide
              Actionable Insights For Risk Mitigation.
            </p>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {!isSubmitted && (
              <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <Input
                  name="name"
                  placeholder="Full Name"
                  value={data.name}
                  onChange={handleChange}
                />

                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={data.email}
                  onChange={handleChange}
                />

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
                  <Input
                    name="organizationName"
                    placeholder="Organization Name"
                    value={data.organizationName}
                    onChange={handleChange}
                  />

                  <Input
                    name="domain"
                    placeholder="Domain"
                    value={domain}
                    disabled={true}
                  />
                </div>

                <Button isLoading={isLoading} onClick={handleSubmit}>Get My Report</Button>
              </div>
            )}


            <div style={{ marginTop: "50px" }} />
        </div>
          </div>
          <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>

            {alert}
          </div>
      </div>
    </div>
  );
};

export default FeatureReport;