import React from 'react';

const Input = ({ type = 'text', placeholder, variant = 'default', style, ...props }) => {
  const styles = {
    base: {
      width: '100%',
      padding: '12px',
      backgroundColor: 'rgba(245, 255, 252, 0.05)',
      border: 'none',
      color: 'white',
      borderRadius: '4px',
      fontSize: '1rem',
      transition: 'background-color 0.2s',
    },
    default: {
      backgroundColor: 'rgba(245, 255, 252, 0.05)',
    },
    primary: {
      backgroundColor: '#00FFB2',
      color: '#0F1A1B',
    },
    secondary: {
      backgroundColor: '#6c757d',
      color: '#fff',
    },
    dark: {
      backgroundColor: '#343a40',
      color: '#fff',
    },
  };

  const combinedStyles = {
    ...styles.base,
    ...styles[variant],
    ...style,
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      style={combinedStyles}
      {...props}
    />
  );
};

export default Input;