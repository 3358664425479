import React from "react";
import { useNavigate } from "react-router-dom";
import brandLogo from "../pages/assests/brand-logo-dk.svg";
import startupIndia from "../pages/assests/startupIndia.png";
import iso from "../pages/assests/iso.svg";
import linkedin from "../pages/assests/linkedin.svg";
import twitter from "../pages/assests/twitter.svg";
import youtube from "../pages/assests/youtube.svg";
import line70 from "../pages/assests/line70.svg";
import { SocialIcon } from "./footer/SocialIcon";

export default function Footer() {
  const navigate = useNavigate();

  const handleNavigate = (path, hash) => {
    navigate(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <footer className="footer">
      <div className="frame-33">
        <div className="brand-logo-dk" style={{width: "100%"}}>
          <img src={brandLogo} style={{ width: "15vw" }} />
          <p style={{ fontSize: "1vw" }}>WE ARE CERTIFIED</p>
          <div>
            <img src={startupIndia} style={{ width: "10vw" }} />
            <img src={iso} style={{ width: "4vw" }} />
          </div>
          <div className="rights-reserved">
            © 2025 Apni Sec. All rights reserved.
          </div>
        </div>

        <div className="frame-34">
          <SocialIcon />

          <div className="flinks" style={{width: "100%", display: "flex", justifyContent: "space-around"}}>
            <div className="frame-35">
              <div className="text-wrapper-45">Company</div>

              <div className="frame-36">
                <div
                  className="flink-options"
                  onClick={() => handleNavigate("/", "")}
                >
                  Home
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/cloud-security", "")}
                >
                  Solutions
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/", "process")}
                >
                  Process
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/", "report")}
                >
                  Report
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/", "services")}
                >
                  Services
                </div>
              </div>
            </div>

            <div className="frame-35">
              <div className="text-wrapper-45">Services</div>

              <div className="frame-36">
                <div
                  className="flink-options"
                  onClick={() => handleNavigate("/dark-eye-watcher", "")}
                >
                  Dark Eye Watcher
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/cloud-security", "")}
                >
                  Cloud Security
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/vciso", "")}
                >
                  Virtual CISO
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/red-team-assesment", "")}
                >
                  Red Team Assessment
                </div>

                <div
                  className="text-wrapper-32"
                  onClick={() => handleNavigate("/vapt", "")}
                >
                  VAPT
                </div>
              </div>
            </div>
          </div>

          <div className="privacy-policy-terms">
            Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms And Services
          </div>
        </div>
      </div>
    </footer>
  );
}

const style = {
  link: {
    color: "#F5FFFC",
    opacity: "0.8",
    fontSize: "1.5rem",
    margin: "0px",
    padding: "0px",
    cursor: "pointer",
  },
};
