import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Testimonies.css";
const testimonials = [
  {
    text: `Working with Apni has been a game-changer for our organization. Their penetration testing services provided us with critical insights into our vulnerabilities, allowing us to strengthen our defenses before any real threats emerged. The risk assessment process was thorough and eye-opening, identifying potential gaps we hadn't considered. Most importantly, their mitigation strategies were practical, effective, and tailored to our needs. We now operate with greater confidence, knowing our systems are secure and resilient. I highly recommend Apni Sec to any organization looking to safeguard their assets and data.`,
    author: "Moiz Arsiwala",
    title: "Co-founder, WorkIndia",
    image:
      "https://play-lh.googleusercontent.com/F1ojiMAAwyvoJ1eisXPvbWu6stVUFLnx21x-awuRr9apKyYTVx5hHvZS0XP_-jgXWk8",
  },
  {
    text: `We've been working with Apni Sec for over 3 year, and they've completely changed the way we handle cybersecurity. From day one, they really took the time to understand our business and put together a security plan that fits our needs perfectly. Their tools—like threat detection and monitoring—make us feel confident that our data is safe and compliant with industry rules. Plus, their team is super responsive, easy to work with, and always goes the extra mile. We couldn't ask for a better partner to help keep us secure.`,
    author: "Praveen Kumar",
    title: "CTO, Livspace",
    image: "https://www.finsmes.com/wp-content/uploads/2016/08/livspace.jpg",
  },
  {
    text: `Apni Sec is proactive, skilled, and vigilant, anticipating and mitigating risks to protect the organization. They communicate effectively and stay innovative in the ever-evolving security landscape. They handle incidents with precision and uphold ethical standards while ensuring compliance. They enable trust and resilience, helping safeguard sensitive data and maintain business continuity. Their dedication and expertise make them invaluable guardians of organizational safety.`,
    author: "Sourav Pathak",
    title: "Principal Engineer, OZiva",
    image:
      "https://play-lh.googleusercontent.com/F68vJhqcWZAXTagwqhWBZu_pdpaCV2XcwWJUPbSpSHnU_5ZFD6OHn3_Bn-MFlaWH5g-f",
  },
  {
    text: `Our partnership with Apni Sec has been an absolute game-changer for Rang De’s IT security. Their proactive approach keeps us a step ahead of potential threats, ensuring that we stay vigilant and prepared. Their regular audits, mock drills are not just thorough but also educational—they help us understand our vulnerabilities and address them before they become issues. One of the most impactful aspects of their service is their ability to decode security breaches in other industries and translate those lessons into practical safeguards for us. We especially look forward to their regular awareness sessions for the team. With Apni Sec, we have learnt that every team member is responsible for IT Security.`,
    author: "Smita Ram",
    title: "Founder, RangDe",
    image:
      "https://media.licdn.com/dms/image/v2/C560BAQHpfBktzte1_A/company-logo_200_200/company-logo_200_200/0/1643716539191/wearerangde_logo?e=2147483647&v=beta&t=DdNhsRzgjhLykLHx2DQZ0ib5J1tpTIl53Ea6dW7T8z0",
  },
  {
    text: `Apni Sec has been a pleasure to deal with for the past three years, and their influence on the cybersecurity posture of our company has been nothing short of revolutionary. Being a dynamic company with ever-more-complex security requirements, our performance in protecting confidential data and reducing risk has been greatly aided by having a reliable vCISO partner.

Apni Sec spent time learning about our company, its objectives, and the particular security issues we face. Their customised strategy assisted us in creating a strong cybersecurity architecture that satisfies our unique requirements as well as industry best practices. Our executive leadership and IT departments have regularly benefited greatly from their team's proficiency in cybersecurity, risk management, and regulatory compliance.

Comprehensive risk assessments, frequent security audits, strategic guidance on enhancing security infrastructure, and incident response planning have all been beneficial to us throughout the course of the three-year partnership. Our reputation and operational security have been enhanced by their assistance in achieving and maintaining compliance with industry standards, such as CERTin and ISO.

In addition to the technical components, Apni Sec has been a great partner in helping our organisation develop a security-first culture. They have done awareness campaigns, provided our workers with enlightening training, and assisted us with proactive and reactive security measures, making sure we are always ready for anything.

They are an essential part of our team because of their professionalism, dependability, and flexibility in responding to the always shifting threat scenario. We appreciate their advice and anticipate carrying on this collaboration for many years to come. To any organisation looking for cybersecurity professionals, they come highly recommended.`,
    author: "Jagmeet Singh",
    title: "Engineering Manager at OZiva",
    image:
      "https://www.oziva.in/cdn/shop/t/10/assets/OZiva_logo_svg.svg?v=40952796546038467691629803346",
  },
  {
    text: `Apni Sec has been Rang De's security partners for 2 years now. 

In the past, our experience with security companies was for VAPT testing: We would hire someone to do VAPT, and they would do it and give us a report, and that was the end of it. With Apni Sec, there is a reason I used the word "partners", because that's the difference - they are on board and involved in our security all the time. It is a long-term relationship rather than just for a particular task. This has made a big positive difference to us.

Here are some specific reasons I highly recommend them:

1. They are geeks, who are genuinely passionate and enthusiastic about security. This is much more valuable than someone for whom it is just a "job".

2. They have expertise in the subject matter. We frequently consult them on things we don't know about ourselves, from possible loopholes in a feature we are building, to data leaks which happen in the industry.

3. Innovation: They do a lot of creative solutions, such as combing the dark web for security compromises specifically relating to Rang De. It has happened a few times now that one of our customer's computer has gotten compromised and their credentials leaked, and Apni Sec's solution has alerted us about this. In these cases it's often we who are the first to inform the customer about their computer being hacked! Apni Sec has also done clever security drills within the company, which has helped a lot in getting employees to be aware and compliant.

4. Initiative: They devised and implemented our Bug Bounty Program themselves; including all communications and ticket tracking.

5. Responsive: We communicate with them via Slack; and they are always a message away for anything.

6. Tools: They have done a lot of clever automations and integrations in various systems such as AWS, GitHub, Jira, Slack and Gmail, which has drastically improved our security handling.

Apni Sec is the kind of company needed for security nowadays. We can no longer treat security as bureaucratic / a checkbox to appease regulators / a once-a-year activity. It has to be constant, visible, evolving, high-priority, and smart. I highly, highly recommend them.`,
    author: "Sunil D'Monte",
    title: "Technical Architect at RangDe",
    image:
      "https://rangde.imgix.net/rangde-logo.svg?auto=compress,format&w=64&h=50",
  },
];

export default function Testimonies({ activePage }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [slideInterval, setSlideInterval] = useState(8000); // Default: 8 seconds

  const updateSlider = (index) => {
    setCurrentIndex(index);
  };

  const toggleReadMore = (index) => {
    if (expandedIndex === index) {
      // Reset to default interval when collapsing
      setSlideInterval(8000);
      setExpandedIndex(null);
    } else {
      // Extend time by 20 seconds
      setSlideInterval(28000);
      setExpandedIndex(index);
    }
  };

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, slideInterval); // Change slide every 8 seconds
    return () => clearInterval(interval);
  }, [slideInterval]);

  // Define a background color for each active page
  const backgroundColors = {
    home: "rgb(245, 255, 252)", // Light blue
    vciso: "rgb(245, 255, 252)", // Misty rose
    darkeye: "rgb(250, 250, 250)", // Lavender
    contactUs: "#0f1a1b", // Lemon chiffon
    cloudsecurity: "rgba(139, 240, 251, 0.1)",
    vapt: "rgba(84, 241, 149, 0.1)",
    redteam: "rgba(255, 171, 171, 0.1)",
  };

  const bordercolor = {
    home: "rgb(0, 255, 178)", // Light blue
    vciso: "rgb(0, 255, 178)", // Misty rose
    darkeye: "rgb(202, 202, 202)", // Lavender
    cloudsecurity: "rgba(139, 240, 251)",
    vapt: "rgb(84, 241, 129)",
    redteam: "rgb(255, 171, 171)",
  };

  const testimonyStyle = {
    backgroundColor: backgroundColors[activePage] || "var(--primary)",
    borderBottom: "0.5px solid",
    borderColor: bordercolor[activePage] || "var(--primary)",
    height: "fit-content",
  };

  return (
    <div className="testimonial-slider">
      <div
        className="slider-wrapper"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {testimonials.map((testimonial, index) => {
          const isLongText = testimonial.text.length > 800;
          const displayedText =
            expandedIndex === index
              ? testimonial.text
              : testimonial.text.slice(0, 800) + (isLongText ? "..." : "");

          return (
            <div
              className="testimonial-card"
              key={index}
              style={testimonyStyle}
            >
              <p
                className={`testimonial-text ${
                  expandedIndex === index ? "expanded" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: displayedText.replace(/\n/g, "<br/>"),
                }}
              >
                {/* {displayedText} */}
              </p>
              {isLongText && (
                <span
                  className="read-more"
                  onClick={() => toggleReadMore(index)}
                >
                  {expandedIndex === index ? " Read Less" : " Read More"}
                </span>
              )}

              <div className="author-icon-slider">
                <div className="author-info">
                  <img
                    className="author-icon"
                    src={testimonial.image}
                    alt={`${testimonial.author}'s Icon`}
                  />

                  <div>
                    <h4 className="author-name">{testimonial.author}</h4>
                    <p className="author-title">{testimonial.title}</p>
                  </div>
                </div>
                <div className="slider-dots">
                  {testimonials.map((_, index) => (
                    <span
                      key={index}
                      className={`dot ${
                        currentIndex === index ? "active" : ""
                      }`}
                      onClick={() => updateSlider(index)}
                    ></span>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Slider dots */}
    </div>
  );
}
