import linkedin from "../../pages/assests/linkedin.svg";
import twitter from "../../pages/assests/twitter.svg";
import youtube from "../../pages/assests/youtube.svg";
import line70 from "../../pages/assests/line70.svg";

export const SocialIcon = () => {
  return (
    <div className="social-icon">
      <a
        href="https://www.linkedin.com/company/apnisec"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="cloudsecurity-socialIcon"
          alt="LinkedIn"
          src={linkedin}
        />
      </a>

      <img className="cloudsecurity-line-70" alt="Line" src={line70} />

      <a href="https://x.com/apnisec" target="_blank" rel="noopener noreferrer">
        <img
          className="cloudsecurity-socialIcon"
          alt="X (formerly Twitter)"
          src={twitter}
        />
      </a>

      <img className="cloudsecurity-line-70" alt="Line" src={line70} />

      <a
        href="https://www.youtube.com/@apnisec"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="cloudsecurity-socialIcon" alt="YouTube" src={youtube} />
      </a>
    </div>
  );
};

export const SocialIconDark = () => {
  return (
    <div
      className="footer-social-icons"
      style={{ display: "flex", justifyContent: "end", gap: "3px" }}
    >
      <a
        href="https://www.linkedin.com/company/apnisec"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M6.94048 5.05755C6.94011 5.87186 6.44608 6.60464 5.69134 6.91035C4.9366 7.21607 4.07187 7.03367 3.5049 6.44917C2.93793 5.86466 2.78195 4.99477 3.1105 4.24969C3.43906 3.50461 4.18654 3.03312 5.00048 3.05755C6.08155 3.09 6.94097 3.97599 6.94048 5.05755ZM7.00048 8.53755H3.00048V21.0575H7.00048V8.53755ZM13.3205 8.53755H9.34048V21.0575H13.2805V14.4875C13.2805 10.8275 18.0505 10.4875 18.0505 14.4875V21.0575H22.0005V13.1275C22.0005 6.95755 14.9405 7.18755 13.2805 10.2175L13.3205 8.53755Z"
            fill="#F5FFFC"
          />
        </svg>
      </a>

      <div
        style={{
          width: "0.5px",
          height: "25px",
          opacity: "0.8",
          background: "#F5FFFC",
        }}
      />

      <a href="https://x.com/apnisec" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M10.4883 14.7076L15.25 21.0566H22.25L14.3917 10.5789L20.9308 3.05664H18.2808L13.1643 8.94242L8.75 3.05664H1.75L9.26086 13.0711L2.31915 21.0566H4.96917L10.4883 14.7076ZM16.25 19.0566L5.75 5.05664H7.75L18.25 19.0566H16.25Z"
            fill="#F5FFFC"
          />
        </svg>
      </a>

      <div
        style={{
          width: "0.5px",
          height: "25px",
          opacity: "0.8",
          background: "#F5FFFC",
        }}
      />

      <a
        href="https://www.youtube.com/@apnisec"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M12.2439 4.05664C12.778 4.05958 14.1143 4.0725 15.5341 4.12937L16.0375 4.15132C17.467 4.219 18.8953 4.33462 19.6037 4.53214C20.5486 4.79759 21.2913 5.57214 21.5423 6.55396C21.942 8.11305 21.992 11.156 21.9982 11.8924L21.9991 12.045V12.0557C21.9991 12.0557 21.9991 12.0594 21.9991 12.0665L21.9982 12.2191C21.992 12.9555 21.942 15.9985 21.5423 17.5576C21.2878 18.543 20.5451 19.3176 19.6037 19.5794C18.8953 19.7769 17.467 19.8925 16.0375 19.9602L15.5341 19.9821C14.1143 20.039 12.778 20.0519 12.2439 20.0549L12.0095 20.0557H11.9991C11.9991 20.0557 11.9956 20.0557 11.9887 20.0557L11.7545 20.0549C10.6241 20.0487 5.89772 19.9976 4.39451 19.5794C3.4496 19.3139 2.70692 18.5394 2.45587 17.5576C2.0562 15.9985 2.00624 12.9555 2 12.2191V11.8924C2.00624 11.156 2.0562 8.11305 2.45587 6.55396C2.7104 5.5685 3.45308 4.79396 4.39451 4.53214C5.89772 4.11387 10.6241 4.06286 11.7545 4.05664H12.2439ZM9.99911 8.55578V15.5557L15.9991 12.0557L9.99911 8.55578Z"
            fill="#F5FFFC"
          />
        </svg>
      </a>
    </div>
  );
};
